import React, { useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import robot from '../../Assets/png/robot.png';
import './institute.css';

const Institute = () => {

  const [imagesrc, setImageSrc] = useState(robot); // Initial image source
  const [fileName, setFileName] = useState("");
  const [themeColor, setThemeColor] = useState("#00ADC4");

  const onClose = () => {
    message.info("Action cancelled.");
  };
  const onFinish = (values) => {
    console.log(values)
    message.success("Update successful!"); // Show success message
  };
  // const onFinish = (values) => {
  //   console.log("Form values:", values);
  // };

  const handleImageUpload = (file) => {
    const reader = new FileReader(); // To read the file as a URL
    reader.onload = () => {
      setImageSrc(reader.result); // Update the image source
    };
    reader.readAsDataURL(file); // Convert the uploaded file to a Data URL
    return false; // Prevent default upload behavior
  };

  const handleFileChange = (file) => {
    if (file && file.name) {
      setFileName(file.name); // Set the file name in state
    }
    return false; // Prevent default upload behavior
  };

  const handleThemeChange = (e) => {
    setThemeColor(e.target.value); // Update the theme color
  };


  return (
    <div className="institutecontainer">
      <div className="imageshadow" >
        <div className="Wholeinstitutelogo" >
          <img src={imagesrc} alt="logo" className="Wholeinstitutelogoimage"/>
        </div>
      </div>
      <div style={{ marginBottom: "24px" }}>
        <Upload
          beforeUpload={handleImageUpload}
          showUploadList={false}
        >
          <Button
            icon={<UploadOutlined />}
            style={{
              borderRadius: "8px",
              background: "#00ADC4",
              color: "white",
              marginLeft: '40px'
            }}
          >
            Upload Logo
          </Button>
        </Upload>
      </div>
      <Form layout="vertical" onFinish={onFinish} style={{ borderRadius: '5px' }}>
        {/* <div style={{ marginLeft: "40px", marginTop: '10px' }}>
          <Row gutter={16} >

            <Col span={12}>
              <Form.Item
                label="Institute Name"
                name="instituteName"
                rules={[{ required: true, message: "Please enter institute name" }]}
              >
                <Input placeholder="Enter Institute Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Principal Name"
                name="principalName"
                rules={[{ required: true, message: "Please enter principal name" }]}
              >
                <Input placeholder="Enter Principal Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: "Please enter address" }]}
              >
                <Input placeholder="Enter Address" />

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email ID"
                name="emailID"
                rules={[{ required: true, message: "Please enter Email ID" }]}
              >
                <Input placeholder="Enter EmailID" />

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Landline"
                name="landline"
                rules={[{ required: true, message: "Please enter Landline" }]}
              >
                <Input placeholder="Enter Landline" />

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phonenumber"
                name="phonenumber"
                rules={[{ required: true, message: "Please enter Phonenumber" }]}
              >
                <Input placeholder="Enter Phonenumber" />

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Cover Image" name="coverImage">
                <div style={{ backgroundColor: 'white', width: '250px', display: 'flex' }}>
                  <Input style={{ width: '150px', border: 'none' }} value={fileName} readOnly placeholder="No file chosen" />
                  <Upload style={{ borderRadius: "8px" }}
                    beforeUpload={handleFileChange}
                    showUploadList={false}>
                    <Button
                      icon={<UploadOutlined />}
                      className="insaddbtnacc"
                    >
                      Choose file
                    </Button>
                  </Upload>
                </div>

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Theme" name="theme">
                <div style={{backgroundColor:'white',width:'50%',display:'flex'}}>
                  
                  <Input
                    type="color"
                    value={themeColor} // Bind input to state
                    onChange={handleThemeChange} // Update theme on change
                    className="themehandlerinput"
                    variant="borderless"
                    
                  />
                  <Input 
                  className="themeinputbox"
                  variant="borderless"
                  value={themeColor} 
                  
                  />
                </div>

              </Form.Item>
            </Col>
          </Row>
        </div> */}
        <p style={{ fontSize: '15px', marginTop: '20px', marginLeft: '20px' }}>
          School / Institute Information</p>
        <div className="boxshadowinstitute" style={{ borderRadius: '10px', padding: '10px', background: '#ECF0F3', boxshadow: '18px 18px 30px 0px #D1D9E640,-18px -18px 30px 0px #FFFFFF' }}>
          <Row gutter={16} >
            <Col span={24} >
              <Form.Item
                label="School Name"
                name="schoolname"
                rules={[
                  { required: true, message: " School Name is required!" },
                ]}
              >
                <Input size="large" placeholder="Enter School Name" style={{ width: '100%', background: '#F3F3F3', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Primary Email"
                name="primaryemail"
                rules={[
                  { required: true, message: "Primary Email is required!" },
                  { type: "email", message: "Enter a valid email!" },

                ]}
              >
                <Input size="large" placeholder="Enter Primary Email" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="contactnumber"
                rules={[
                  { required: true, message: "Contact Number is required!" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Enter a valid 10-digit Contact Number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Contact Number" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} >
            <Col span={24} >
              <Form.Item
                label="School Address"
                name="schooladdress"
                rules={[
                  { required: true, message: "School Address is required!" },
                ]}
              >
                <Input size="large" placeholder="Enter School Address" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  { required: true, message: "City is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter City" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { required: true, message: "State is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter State" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Country is required!" },
                ]}
              >
                <Input size="large" placeholder="Enter Country" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Area"
                name="area"
                rules={[
                  { required: true, message: "Area is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter Area" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                label="Theme"
                name="theme"
                rules={[
                  { required: true, message: "Theme is required!" },
                
                ]}
              >
                <Input size="large" placeholder="Enter Theme" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item label="Theme" name="theme">
                <div style={{ backgroundColor: 'white',height:'40px',  display: 'flex',borderRadius:'10px',border: '1px solid #00ADC4' }}>

                  <Input
                    type="color"
                    value={themeColor} // Bind input to state
                    onChange={handleThemeChange} // Update theme on change
                    className="themehandlerinput"
                    variant="borderless"
                  />
                  <Input
                    className="themeinputbox"
                    variant="borderless"
                    value={themeColor}
                  />
                </div>

              </Form.Item>
            </Col>

          </Row>
        </div>
        <p style={{ fontSize: '15px', marginTop: '20px', marginLeft: '20px' }}>
          Admin Detail
        </p>

        <div style={{ padding: '10px', background: 'white', marginTop: '10px', borderRadius: '10px' }} className="boxshadowinstitute">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Admin Name"
                name="adminname"
                rules={[
                  { required: true, message: "Admin Name is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter Admin Name" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Admin Email"
                name="adminemail"
                rules={[
                  { required: true, message: "Admin Email is required!" },
                  { type: "email", message: "Enter a valid Email!" },


                ]}
              >
                <Input size="large" placeholder="Enter Admin Email" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Admin Phone Number"
                name="Adminphonenumber"
                rules={[
                  { required: true, message: "Admin Phone Number is required!" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Enter a valid 10-digit Contact Number!",
                  },

                ]}
              >
                <Input size="large" placeholder="Enter Admin Phone Number" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="School Type"
                name="schooltype"
                rules={[
                  { required: true, message: "School Type is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter School Type" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <p style={{ fontSize: '15px', marginTop: '20px', marginLeft: '20px' }}>
          Branch Detail
        </p>
        <div className="boxshadowinstitute" style={{ borderRadius: '10px', padding: '10px', marginTop: '10px', background: 'white', boxshadow: '18px 18px 30px 0px #D1D9E640,-18px -18px 30px 0px #FFFFFF' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Branch Name"
                name="branchname"
                rules={[
                  { required: true, message: "Branch Name is required!" },

                ]}
              >
                <Input size="large" placeholder="Branch Name" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch Address"
                name="branchaddress"
                rules={[
                  { required: true, message: "Branch Address is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter Branch Address" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Branch Contact Number"
                name="branchcontactnumber"
                rules={[
                  { required: true, message: "Branch Contact Number is required!" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Enter a valid 10-digit Contact Number!",
                  },

                ]}
              >
                <Input size="large" placeholder="Enter Branch Contact Number" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch Admin Name"
                name="branchadminname"
                rules={[
                  { required: true, message: "Branch Admin Name is required!" },

                ]}
              >
                <Input size="large" placeholder="Enter Branch Admin Name" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Branch Admin Email"
                name="branchadminemail"
                rules={[
                  { required: true, message: "Branch Admin Email is required!" },
                  { type: "email", message: "Enter a valid email!" },
                ]}
              >
                <Input size="large" placeholder="Enter Branch Admin Email" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch Admin Mobile Number"
                name="branchadminmobilenumber"
                rules={[
                  { required: true, message: "Branch Admin Mobile Number is required!" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Enter a valid 10-digit Contact Number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Branch Admin Mobile Number" style={{ width: '100%', border: '1px solid #00ADC4' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="form-buttons">
          <div className='institutebtn'>

            <Form.Item >
              <Button type="primary" htmlType="submit" className="updateinstitute" style={{ background: themeColor, marginRight: '5px' }}>
                Update
              </Button>
              <Button type="primary" htmlType="button" onClick={onClose} style={{ background: themeColor, borderColor: themeColor }}>
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Institute;

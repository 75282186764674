import React from "react";
import { Input, Select, Upload, Button, Switch, DatePicker, Form, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Row, Col } from 'antd';
import icon from '../../Assets/png/icon.png'
// import './driver.css'
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

function driveropr({ record, onClose, type, selectedId }) {

    const MAX_FILE_SIZE = 500 * 1024; // 500 KB in bytes

    const handleBeforeUpload = (file) => {
        if (file.size > MAX_FILE_SIZE) {
            message.error("File size exceeds 500 KB. Please upload a smaller file.");
            return Upload.LIST_IGNORE; // Prevent upload
        }
        return true; // Allow upload
    };



    return (
        <>
            {!selectedId ?
                <div className="Accodamicwholeopr" >
                    <h2>Add Driver</h2>
                    <h3 className="Driver">Driver Details</h3>
                    <Form layout="vertical" style={{ borderRadius: '7px' }}>
                        <div className="Driverform">
                            <Row gutter={16} className="driverrowform">
                                <Col span={8} >
                                    <Form.Item name="FullName" label="FullName" style={{ marginTop: '20px' }}>
                                        <Input placeholder="FullName" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Dateofbirth" label="Date of Birth" style={{ marginTop: '20px' }}>
                                        <Input placeholder="Date of Birth" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="EmailId" label="Email Id" style={{ marginTop: '20px' }}>
                                        <Input placeholder="Email Id" className="inputcon" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} className="driverrowform">
                                <Col span={8} >
                                    <Form.Item name="address" label="Address">
                                        <Input placeholder="Address" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Assigned Vehicle" label="Assigned Vehicle">
                                        <Input placeholder="Assigned Vehicle" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Dateofjoining" label="Date of Joining">
                                        <Input placeholder="Date of Joining" className="inputcon" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div >
                            <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Other Details</h3>
                            <div className="Driverform">
                                <Row gutter={16} className="driverrowform">
                                    <Col span={8} >
                                        <Form.Item name="Conductor Name" label="Conductor Name" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Conductor Name" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Mobile Number" label="Mobile Number" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Mobile Number" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Transportation Staff" label=" Transportation Staff" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Transportation Staff" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Dateofbirth" label="Date of Birth" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Date of Birth" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="EmailId" label="Email Id" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Email Id" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Photo Upload</h3>
                            <div >
                                <div className='whitebagcontainer'>
                                    <div className="upload">
                                        <Upload maxCount={1} beforeUpload={() => false} >
                                            <Button icon={<UploadOutlined />} className="modifybutton" >
                                                Upload
                                            </Button>
                                        </Upload>
                                        <p className="file-size-info" >
                                            <InfoCircleOutlined /> Max file size should be 500kb
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className="form-buttons">
                        <Button className="modifybutton"
                            onClick={() => { /* handle add or edit */ }}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                </div> :
                <div className="Accodamicwholeopr" >
                    <h3 style={{ fontFamily: 'poppins', fontWeight: '400', fontStyle: 'bold' }}>{type == 'edit' ? `Edit driver` : `View driver`}</h3>
                    <h2 className='Accadamich3'>Driver Details</h2>
                    <Form layout="vertical" style={{ borderRadius: '7px' }}>
                        <div className="Driverform">
                            <Row gutter={16} className="driverrowform">
                                <Col span={8} >
                                    <Form.Item name="FullName" label="FullName" style={{ marginTop: '20px' }}>
                                        <Input placeholder="FullName" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Dateofbirth" label="Date of Birth" style={{ marginTop: '20px' }}>
                                        <Input placeholder="Date of Birth" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="EmailId" label="Email Id" style={{ marginTop: '20px' }}>
                                        <Input placeholder="Email Id" className="inputcon" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} className="driverrowform">
                                <Col span={8} >
                                    <Form.Item name="address" label="Address">
                                        <Input placeholder="Address" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Assigned Vehicle" label="Assigned Vehicle">
                                        <Input placeholder="Assigned Vehicle" className="inputcon" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Dateofjoining" label="Date of Joining">
                                        <Input placeholder="Date of Joining" className="inputcon" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div >
                            <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Other Details</h3>
                            <div className="Driverform">
                                <Row gutter={16} className="driverrowform">
                                    <Col span={8} >
                                        <Form.Item name="Conductor Name" label="Conductor Name" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Conductor Name" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Mobile Number" label="Mobile Number" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Mobile Number" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Transportation Staff" label=" Transportation Staff" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Transportation Staff" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="Dateofbirth" label="Date of Birth" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Date of Birth" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="EmailId" label="Email Id" style={{ marginTop: '20px' }}>
                                            <Input placeholder="Email Id" className="inputcon" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                    <div className="form-buttons">
                        <Button className="modifybutton">Update</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                </div>
            }
        </>
    );
}

export default driveropr;



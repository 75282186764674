import React, { useState, Children,useEffect} from "react";
import { Layout, Menu, Typography, Tabs } from "antd";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../base/Navbar";
// import './sidebar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faGraduationCap, faUserTie, faChalkboardTeacher, faUsers, faTag, faUsersCog, faFileAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import { getUserData } from "../../helper";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;


const menuItems = [
  {
    key: "1",
    label: "Dashboard",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-th-large fa-lg"></i>
      </span>),
    link: "/",
    permissions: ["super-admin", "admin", "drivers", "teacher"],
  },
  {
    key: "2",
    label: "Academic Employee",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-chalkboard-teacher"></i>
      </span>
    ),

    link: "/academic-employee",
    permissions: ["super-admin", "admin", "teacher"],
  },
  {
    key: "3",
    label: "Students",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-graduation-cap"></i>
      </span>
    ),
    link: "/students",
    permissions: ["super-admin", "admin", "drivers"],
  },
  {
    key: "4",
    label: "Parents",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-graduation-cap"></i>
      </span>
    ),
    link: "/parent",
    permissions: ["super-admin", "admin","teacher"],
  },
  {
    key: "5",
    label: "Drivers",
    icon: (
      <span className="menu-icon">
<i class="fas fa-user-alt"></i>   
   </span>
    ),
    link: "/drivers",
    permissions: ["super-admin", "admin", "drivers", "teacher"],
  },
  {
    key: "6",
    label: "Notices",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-file-alt"></i>
      </span>
    ),

    link: "/notices",
    permissions: ["super-admin", "admin", "drivers", "teacher"],
  },

  {
    key: "7",
    label: "Circular",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-th-large fa-lg"></i>
      </span>

    ),

    link: "/circular",
    permissions: ["super-admin", "drivers","admin", "teacher"],
  },
  {
    key: "8",
    label: "Fees",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-tag"></i>
      </span>
    ),

    link: "/fees",
    permissions: ["super-admin", "admin", "teacher"],
  },
  {
    key: "9",
    label: "User Management",
    icon: (
      <span  className="menu-icon">
        <i class="fas fa-users"></i>
      </span>
    ),
    
    link: "/user-management",
    permissions: ["super-admin", "admin"],

  },
  {
    key: "10",
    label: "Reports",
    icon: (
      <span  className="menu-icon">
        <i class="fas fa-th-large fa-lg"></i>
        </span>
    ),
    link: "/reports",
    permissions: ["student", "super-admin", "admin", "drivers", "teacher"],
  },
  {
    key: "11",
    label: "Department Admin",
    icon: (
      <span className="menu-icon">
        <i class="fas fa-user-cog"></i>
      </span>
    ),
    

    link: "/department-admin",
    permissions: ["super-admin","admin"],
  },
  
  {
    key: "12",
    label: "Settings",
    icon: (
      <sapn className="menu-icon">
        <i class="fas fa-cog"></i>
      </sapn>
    ),
    link: "/setting",
    
    permissions: ["super-admin", "admin", "drivers", "teacher"],
  },
];

const Sidebar = (props) => {
  const userData = getUserData();
  const [value, setvalue] = useState('')
  const [collapsed, setCollapsed] = useState(false);


  const menuItemStyle = {
    color: "white",
    fontSize: "14px",
    fontFamily: "Nunito",
  };
  const iconStyle = { width: "16px", height: "16px", filter: "invert(1)" };

 
console.log(userData,"has");


  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = (e) => setCollapsed(e.matches); // Auto-collapse in mobile view

    // Set initial state and add listener
    handleResize(mediaQuery);
    mediaQuery.addListener(handleResize);

    return () => mediaQuery.removeListener(handleResize); // Cleanup listener
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider

      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ background: "#00ADC4", color: "#fff",borderTopRightRadius: '20px',borderBottomRightRadius: '20px',zIndex:'100',position:'sticky',height: "100vh",top:0}}
      width={250}
      trigger={null}
        >
          <div
           
           
          >
            <Typography style={{
              fontSize:'18px',
              color: "white",
              height: "32px",
              margin: "16px",
              textAlign: "center",
            }} className="logotypo">
              {userData.name}
            </Typography>
          </div>

          <Menu
          className="scroll"
            style={{
              backgroundColor: "#00ADC4",
              color: "white",
              fontFamily: "poppins",
             height:'85vh',
             overflowY:'scroll',
            }}
            mode="inline"
          >
            {menuItems
              .filter((item) => item.permissions.includes(userData.role))
              .map(({ key, label, icon, link, submenu }) => {
                if (submenu) {
                  return (
                    <SubMenu
                      key={key}
                      title={label}
                      icon={icon}
                      style={menuItemStyle}
                    >
                      {submenu
                        .filter((subItem) =>
                          subItem.permissions.includes(userData.role)
                        )
                        .map(
                          ({ key: subKey, label: subLabel, link: subLink }) => (
                            <Menu.Item key={subKey} style={menuItemStyle}>
                              <Link
                                to={subLink}
                                style={{
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                                onClick={() => setvalue(subLabel)}
                              >
                                {subLabel}
                              </Link>
                            </Menu.Item>
                          )
                        )}
                    </SubMenu>
                  );
                }

                return (
                  <Menu.Item key={key} icon={icon} style={menuItemStyle}>
                    <Link
                      to={link}
                      style={{ color: "inherit", textDecoration: "none" }}
                      onClick={() => setvalue(label)}

                    >
                      {label}
                    </Link>
                  </Menu.Item>
                );
              })}
          </Menu>
        </Sider>
        <div style={{ width: "100%" }}>
          <div
            style={{
              // position: "absolute",
              width: "100%",
              zIndex: "10",
              left: "0px",
              position:'fixed'
            }}
          >
            <Navbar headerName={value}  userData={userData}/>
          </div>
          <div className="outletcontainer" >
            <div className="outletinnercontainer">
              <div>
                <Outlet />
              </div>
            </div>
          </div>

        </div>
      </Layout>
    </>
  );
};

export default Sidebar;

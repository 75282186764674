import React from 'react';
import { Form, Input, Select, Checkbox, Button, Upload, Switch } from 'antd';
import { Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
// import './studentopr.css';
import { icon } from '../../Assets/png/icon.png'

const { Option } = Select;

const AddStudentForm = ({ selectedId, onClose, record, type }) => {

    console.log(selectedId, type, record, "accadamic");


    return (
        <div >
            {!selectedId ?
                <div className="Studentwholeopr">
                    <h2>Add Student</h2>
                    <Form layout="vertical" >


                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Student Details</h3>

                        <div className='whitebagcontainer'>

                            <Row gutter={16} >
                                <Col span={8} lg={8} >
                                    <Form.Item name="firstName" label="First Name">
                                        <Input placeholder="First Name" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="lastName" label="Last Name">
                                        <Input placeholder="Last Name" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="dateOfBirth" label="Date of Birth">
                                        <Input placeholder="Date of Birth" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>


                            </Row>
                            <Row gutter={16}>
                                <Col span={8} lg={8} md={4}>

                                    <Form.Item label="Gender">
                                        <Select style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} className='selectorclass'>
                                            <Option value="Male">Male</Option>
                                            <Option value="FeMale">FeMale</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={8}>
                                    <Form.Item name="Nationality" label="Nationality">
                                        <Input placeholder="Nationality" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Date of admission" label="Date of admission">
                                        <Input placeholder="Date of admission" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                                    <Form.Item label="Govt uniqueID">
                                        <Input placeholder="Govt uniqueID" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />

                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item name="Register No" label="Register No">
                                        <Input placeholder="Register No" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Admission No" label="Admission No">
                                        <Input placeholder="Admission No" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>

                                <Col span={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Blood Group">
                                        <Select >
                                            <Option value="A+">A+</Option>
                                            <Option value="A-">A-</Option>
                                            <Option value="B+">B+</Option>
                                            <Option value="B-">B-</Option>
                                            <Option value="AB+">AB+</Option>
                                            <Option value="AB-">AB-</Option>
                                            <Option value="O+">O+</Option>
                                            <Option value="O-">O-</Option>
                                        </Select>
                                    </Form.Item>

                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Religion" label="Religion">
                                        <Input placeholder="Religion" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="select class" label="select class">
                                        <Input placeholder="select class" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="section" label="section">
                                        <Input placeholder="section" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Department" label="Department">
                                        <Input placeholder="Department" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Father Details</h3>

                        <div className='whitebagcontainer'>
                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Full name" label="Full name">
                                        <Input placeholder="Full name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Father Aadhar No" label="Father Aadhar No">
                                        <Input placeholder="Father Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row gutter={16}>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <div style={{ width: '200px', marginLeft: '750px' }}>
                                <Checkbox >Address Same as Student</Checkbox>
                            </div> */}
                            <div className='addrescheckbox'>
                                <Checkbox >Address Sames as Student</Checkbox>
                            </div>


                        </div>


                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Mother Details</h3>

                        <div className='whitebagcontainer'>

                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Full Name" label="Full Name">
                                        <Input placeholder="Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Mother Aadhar No" label="Mother Aadhar No">
                                        <Input placeholder="Mother Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='addrescheckbox'>
                                <Checkbox >Address Sames as Student</Checkbox>
                            </div>



                        </div>
                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Guardian Details</h3>

                        <div className='whitebagcontainer'>

                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>

                                <Col span={8} lg={8}>
                                    <Form.Item name="Full Name" label="Full Name">
                                        <Input placeholder="Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Mother Aadhar No" label="Mother Aadhar No">
                                        <Input placeholder="Mother Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='addrescheckbox'>
                                <Checkbox >Address Sames as Student</Checkbox>
                            </div>

                        </div>
                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Photo Upload</h3>

                        <div >
                        <div className='whitebagcontainer'>
                             <div className="upload">
                                 <Upload maxCount={1} beforeUpload={() => false} >
                                     <Button icon={<UploadOutlined />} className="modifybutton" >
                                         Upload
                                     </Button>
                                 </Upload>
                                 <p className="file-size-info" >
                                     <InfoCircleOutlined /> Max file size should be 500kb
                                 </p>
                             </div>
                         </div>
                        </div>
                        <div className='form-buttons'>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="modifybutton"
                                    style={{ marginRight: '8px' }}>
                                    Add
                                </Button>
                                <Button htmlType="button" onClick={onClose}>Cancel</Button>
                            </Form.Item>
                        </div>

                    </Form>
                </div> :
                <div style={{ padding: '20px', backgroundColor: '#ECF0F3', borderRadius: '8px' }}>
                    <div style={{ width: 200, height: 200 }}>
                        <img src={"https://i.pravatar.cc/40?img=12"} alt="ii" style={{ width: 200, height: 200 }} />
                    </div>
                    <Form layout="vertical">
                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Student Details</h3>
                        <div style={{ border: '1px solid #FFFFFF', borderRadius: '7px', background: '#F7F7F7', background: '#F7F7F7', padding: '15px' }}>
                            <Row gutter={16} >
                                <Col span={8} >
                                    <Form.Item name="firstName" label="First Name">
                                        <Input placeholder="First Name" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="lastName" label="Last Name">
                                        <Input placeholder="Last Name" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="dateOfBirth" label="Date of Birth">
                                        <Input placeholder="Date of Birth" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                                    <Form.Item label="Gender">
                                        <Select style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }}>
                                            <Option value="Male">Male</Option>
                                            <Option value="FeMale">FeMale</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item name="Nationality" label="Nationality">
                                        <Input placeholder="Nationality" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Date of admission" label="Date of admission">
                                        <Input placeholder="Date of admission" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>

                                <Col span={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Blood Group">
                                        <Select >
                                            <Option value="A+">A+</Option>
                                            <Option value="A-">A-</Option>
                                            <Option value="B+">B+</Option>
                                            <Option value="B-">B-</Option>
                                            <Option value="AB+">AB+</Option>
                                            <Option value="AB-">AB-</Option>
                                            <Option value="O+">O+</Option>
                                            <Option value="O-">O-</Option>
                                        </Select>
                                    </Form.Item>

                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Religion" label="Religion">
                                        <Input placeholder="Religion" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="select class" label="select class">
                                        <Input placeholder="select class" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="section" label="section">
                                        <Input placeholder="section" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Department" label="Department">
                                        <Input placeholder="Department" style={{ background: '#EFEFEF', border: '1px solid #E7E7E7', borderRadius: '5px' }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </div>

                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Father Details</h3>

                        <div style={{ border: '1px solid #FFFFFF', borderRadius: '7px', background: '#F7F7F7', background: '#F7F7F7', padding: '15px' }}>
                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="Full name" label="Full name">
                                        <Input placeholder="Full name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Father Aadhar No" label="Father Aadhar No">
                                        <Input placeholder="Father Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='addrescheckbox'>
                                <Checkbox >Address Same as Student</Checkbox>
                            </div>


                        </div>


                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Mother Details</h3>

                        <div style={{ border: '1px solid #FFFFFF', borderRadius: '7px', background: '#F7F7F7', background: '#F7F7F7', padding: '15px' }}>

                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="Full Name" label="Full Name">
                                        <Input placeholder="Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Mother Aadhar No" label="Mother Aadhar No">
                                        <Input placeholder="Mother Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                                <div style={{ width: '200px', marginLeft: '750px' }}>
                                    <Checkbox >Address Same as Student</Checkbox>
                                </div>

                            </Row>

                        </div>
                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Guardian Details</h3>

                        <div style={{ border: '1px solid #FFFFFF', borderRadius: '7px', background: '#F7F7F7', padding: '15px', boxshadow: '0px 1px 4px 0px #00000040' }}>

                            <div style={{ width: '150px', marginLeft: '800px' }}>
                                <Checkbox >Primary Parent</Checkbox>
                            </div>

                            <Row gutter={16}>

                                <Col span={8}>
                                    <Form.Item name="Full Name" label="Full Name">
                                        <Input placeholder="Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Mother Aadhar No" label="Mother Aadhar No">
                                        <Input placeholder="Mother Aadhar No" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Mobile Number" label="Mobile Number">
                                        <Input placeholder="Mobile Number" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="Email Id" label="Email Id">
                                        <Input placeholder="Email Id" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Occupation" label="Occupation">
                                        <Input placeholder="Occupation" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="Address" label="Address">
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ width: '200px', marginLeft: '750px' }}>
                                <Checkbox >Address Same as Student</Checkbox>
                            </div>

                        </div>
                        {type =='edit' || type=="add"?
                         <div >
                        <h3 style={{ margin: '20px', fontFamily: 'Poppins, sans-serif', fontWeight: 500 ,border:'solid 1px'}}>Photo Upload</h3>
                        <div className='whitebagcontainer'>
                             <div className="upload">
                                 <Upload maxCount={1} beforeUpload={() => false} >
                                     <Button icon={<UploadOutlined />} className="modifybutton" >
                                         Upload
                                     </Button>
                                 </Upload>
                                 <p className="file-size-info" >
                                     <InfoCircleOutlined /> Max file size should be 500kb
                                 </p>
                             </div>
                         </div>
                     </div>:null
                        
                    
                    }
                       

                        {type == 'edit' ? <Form.Item label="Allow Access" style={{ marginTop: '20px', marginLeft: '20px' }} valuePropName="checked">
                            <Switch defaultChecked={record?.accessAllowed} />
                        </Form.Item> : null}

                        <div className="form-buttons ">
                            {type == 'edit' ?
                                <Button type="primary" className="modifybutton"
                                    onClick={() => { /* handle add or edit */ }}>
                                    Update
                                </Button> : null}
                            <Button onClick={onClose}>Cancel</Button>
                        </div>

                    </Form>
                </div>

            }
        </div>


    );
};

export default AddStudentForm;

import React, { useState, useEffect } from "react";
import {  Modal,Table,Input,Button,Badge,Avatar,Select,Space,notification,Tag,Row,Col,} from "antd";
import {SearchOutlined,PlusOutlined,EditOutlined,DeleteOutlined,} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilter,faPlus,faPenToSquare,faEye,faTrash,} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Driveropr from "../Component/Driver/driveropr.jsx";
import Sidebar from "../layout/base/Sidebar";

const { confirm } = Modal;

const allData = [
  {
    key: 1,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Admin",
    phoneNumber: "+91 9876543210",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
    email: "suganthi.thomas.eng00201@example.com",
  },
  {
    key: 2,
    name: "Arjun Reddy",
    employeeID: "ENG00202",
    role: "Admin",
    phoneNumber: "+91 9876543211",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
    email: "arjun.reddy.eng00202@example.com",
  },
  {
    key: 3,
    name: "Meera Kapoor",
    employeeID: "ENG00203",
    role: "Admin",
    phoneNumber: "+91 9876543212",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    email: "meera.kapoor.eng00203@example.com",
  },
  {
    key: 4,
    name: "Ishita Sharma",
    employeeID: "ENG00204",
    role: "Admin",
    phoneNumber: "+91 9876543213",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
    email: "ishita.sharma.eng00204@example.com",
  },
  {
    key: 5,
    name: "Ravi Verma",
    employeeID: "ENG00205",
    role: "Admin",
    phoneNumber: "+91 9876543214",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=5",
    email: "ravi.verma.eng00205@example.com",
  },
  {
    key: 6,
    name: "Ayesha Khan",
    employeeID: "ENG00206",
    role: "Admin",
    phoneNumber: "+91 9876543215",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=6",
    email: "ayesha.khan.eng00206@example.com",
  },
  {
    key: 7,
    name: "Karthik Iyer",
    employeeID: "ENG00207",
    role: "Admin",
    phoneNumber: "+91 9876543216",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=7",
    email: "karthik.iyer.eng00207@example.com",
  },
  {
    key: 8,
    name: "Priya Patel",
    employeeID: "ENG00208",
    role: "Admin",
    phoneNumber: "+91 9876543217",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=8",
    email: "priya.patel.eng00208@example.com",
  },
  {
    key: 9,
    name: "Rahul Gupta",
    employeeID: "ENG00209",
    role: "Admin",
    phoneNumber: "+91 9876543218",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=9",
    email: "rahul.gupta.eng00209@example.com",
  },
  {
    key: 10,
    name: "Sneha Roy",
    employeeID: "ENG00210",
    role: "Admin",
    phoneNumber: "+91 9876543219",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=10",
    email: "sneha.roy.eng00210@example.com",
  },
];


function DriverDetails({ userData, isEdit, count }) {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  // const [userData, setUserData] = useState(allData)
  const [driverDataList, setDriverDataList] = useState(allData);

  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(count);
  }, [count]);
  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };

  const columns = [
    ...(isEdit
      ? [
          {
            title: <input type="checkbox" />,
            dataIndex: "checkbox",
            render: () => <input type="checkbox" />,
          },
        ]
      : []),
    {
      title: "Driver Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
    },
    {
      title: "Email ID1",
      dataIndex: "email",
      width:100,     
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      
    },
    {
      title: "Assigned vehicle",
      dataIndex: "assignedvehicle",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={status === "Active" ? "#00ADC4" : "red"}
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "red"}`,
            color: status === "Active" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
          }}
        >
          {status}
        </Tag>
      ),
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    });

  const useronChange = async (value) => {
    if (value) {
      let finded = allData.filter((a) => a.role == value);
      setDriverDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setDriverDataList(allData);
      setTotalcount(allData.length);
    }
  };

  const searchOnChange = (value) => {
    let data = driverDataList.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())   ||
       item.phoneNumber.replace(/\D/g, '').includes(value.replace(/\D/g, ''))
    );
    setDriverDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };

//pagination 
const objectLength = Object.keys(driverDataList).length;

  return (
    <div>
      {!editModuleOpen ? (
        <div className="tablecontainer">
          <div className="tableinnercontainer">
            <Input
              onChange={(e) => searchOnChange(e.target.value)}
              value={search}
              placeholder="Search by Driver Name or ID"
              prefix={<SearchOutlined />}
              className="seachAccadamicemployee"
            />
            <div className="btnacccon">
              <div className="filterbtn" >
                <FontAwesomeIcon icon={faFilter} size="lg" className="icon" />
                <p style={{ marginRight: "5px" }}>Filter</p>
              </div>
              {!isEdit ? (
                <div
                  className="addbtn"
                  onClick={() => openOnChange(null, "add")}
                >
                  <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
                  <p style={{ marginRight: "5px" }}>Add</p>
                </div>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={driverDataList}
            pagination={{
              defaultCurrent: 1, 
              total: objectLength, 
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50'],
              showTotal: (total, range) => (
                <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
              )
              
              }}
            style={{ borderRadius: "8px" }}
          />
        </div>
      ) : (
        <Driveropr
          record={selectedDate}
          onClose={onClose}
          type={teb}
          selectedId={selectedId}
        />
      )}
    </div>
  );
}

export default DriverDetails;

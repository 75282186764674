import { useRef } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Select } from "antd";

import Teacher from '../../../Assets/Svg/teacherattendance.svg';
import './TeacherAttenanceCharts.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const TeacherAttendanceChart = () => {
  const chartRef = useRef(null); // Create a ref to access the chart instance

  // Function to generate the gradient
  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    gradient.addColorStop(0, '#00ADC4'); // Top color
    gradient.addColorStop(1, '#00535E'); // Bottom color
    return gradient;
  };

  // Data object with dynamic gradient
  const data = {
    labels: ['Present', 'Absent'],
    datasets: [
      {
        data: [78.5, 22.5],
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // Return default colors if the chart is not fully rendered yet
            return ['rgba(0, 183, 203, 1)', 'rgba(241, 90, 96, 1)'];
          }

          // Apply gradient to the first segment, keep the second as is
          const gradient = getGradient(ctx, chartArea);
          return [gradient, 'rgba(241, 90, 96, 1)'];
        },
        borderWidth: 0,
        cutout: '70%', // Controls the thickness of the ring
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: false, // Disable tooltips for custom labels
      },
    },
  };

  return (
    <div style={{ position: 'relative', width: '100%',height:'100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2 style={{ fontSize: '14px', fontFamily: 'Poppins', color: '#333' }}>Teacher’s Attendance</h2>
        <Select
          placeholder="Choose gender"
          style={{ width: 100, borderRadius: '50px' }}
          defaultValue="all"
        >
          <Option value="all">Choose department</Option>
          <Option value="Female">Female</Option>
          <Option value="male">male</Option>
          <Option value="other">other</Option>
        </Select>
      </div>
      <div style={{height:'250px' ,width:'100%',position: 'absolute', top: '50%',display:'flex',justifyContent:'center',
          left: '50%',
          transform: 'translate(-50%, -50%)',}}>
      <Doughnut
        ref={chartRef}
        data={data}
        options={options}
        style={{
          // border:'solid 1px red'
          // marginLeft: '5rem' ,
          position:'relative',
        }}
      />
      </div>
      
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px', 
          color: '#333',
          
          // border:'solid 2px red'
        }}
       
      >
        <div className="Teachericonoutercomponent" >
          <img
            src={Teacher}
            alt="Dashboard"
            className="Teachericoninnercomponent"
            style={{
              width: '8rem',
              backgroundColor: '#ECF0F3',
              borderRadius: '50%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.09)',
            }}
          />
        </div>
      </div>
      <div className="chart-labelss" style={{ display: 'flex',justifyContent:'space-between'}}>
        <div className="label present"  >
          <span
            className="dot"
            style={{
              backgroundColor: '#EC7C74',
              marginLeft: '2rem',  
            }}
          ></span>
          <span>Absent</span>
          <span className="percentage"></span>
        </div>
        <div className="label absent">
          <span
            className="dot"
            style={{
              background: 'linear-gradient(129.64deg, #00535E 0%, #00ADC4 115.29%)'

             
            }}
          ></span>
          <span>present</span>
          <span className="percentage"></span>
        </div>
      </div>
    </div>
  );
};

export default TeacherAttendanceChart;

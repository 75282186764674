import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux"
import icon from "../Assets/png/icon.png";
import "./Login.css";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Button, Layout, Form, Checkbox, Typography, notification, } from "antd";
import { sendNotify, fetchApi } from "../helper"
import { checkLogged } from "../../src/store/auth/authSlice"
//let JWT_SECRET = "A7NJ!RJTWL9%jFbFxkOb777=kcJsWdPLUIcNP8hK"

const { Content } = Layout;
const { Title, Text } = Typography;

function App() {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");

  let user = [
    {
      id: 1,
      name: "teacher",
      email: "teacher@gmail.com",
      role: "teacher",
      password: "teacher@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6InRlYWNoZXIiLCJlbWFpbCI6InRlYWNoZXJAZ21haWwuY29tIiwicm9sZSI6InRlYWNoZXIiLCJwYXNzd29yZCI6InRlYWNoZXJAMTIzIiwic3RhdHVzIjoiWSIsImV4cCI6IjEzLTEyLTIwMjUiLCJ0aGVtZUNvbG9yIjoicmVkIiwiaW1nIjoiaHR0cHM6Ly9pLnByYXZhdGFyLmNjLzQwP2ltZz0xIiwiaWF0IjoxNjk5OTI0Njg1fQ.SgOOBhR4VQ8GBMHC2grXzNZ0BM5AnVnG-R0MmF1HTSg",
    },
    {
      id: 2,
      name: "admin",
      email: "admin@gmail.com",
      role: "admin",
      password: "admin@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwibmFtZSI6ImFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJwYXNzd29yZCI6ImFkbWluQDEyMyIsInN0YXR1cyI6IlkiLCJleHAiOiIxMy0xMi0yMDI1IiwidGhlbWVDb2xvciI6InJlZCIsImltZyI6Imh0dHBzOi8vaS5wcmF2YXRhci5jYy80MD9pbWc9MSIsImlhdCI6MTY5OTkyNDczNn0.5PZlQ95ZTzSPcLkGMCdNhsABj1zUb57dbOg3kREoqFk",
    },
    {
      id: 3,
      name: "Driver",
      email: "driver@gmail.com",
      role: "driver",
      password: "driver@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IkRyaXZlciIsImVtYWlsIjoibHJpdmVyQGdtYWlsLmNvbSIsInJvbGUiOiJkcml2ZXIiLCJwYXNzd29yZCI6ImRyaXZlckAxMjMiLCJzdGF0dXMiOiJZIiwiaXAiOiJodHRwczovL2kucHJhdmF0YXIuY2MvNDA/ImltZyI6Imh0dHBzOi8vaS5wcmF2YXRhci5jYy80MD9pbWc9MSIsImV4cCI6IjEzLTEyLTIwMjUiLCJpYXQiOjE2OTk5MjQ4NTV9.5PZYZ_a7h8xxA9fltnp8tIB5tL5mdNJkQG__vsMO6gI",
    },
    {
      id: 4,
      name: "student",
      email: "student@gmail.com",
      role: "student",
      password: "student@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6InN0dWRlbnQiLCJlbWFpbCI6InN0dWRlbnRAZ21haWwuY29tIiwicm9sZSI6InN0dWRlbnQiLCJwYXNzd29yZCI6InN0dWRlbnRAMTIzIiwic3RhdHVzIjoiWSIsImV4cCI6IjEzLTEyLTIwMjUiLCJ0aGVtZUNvbG9yIjoicmVkIiwiaW1nIjoiaHR0cHM6Ly9pLnByYXZhdGFyLmNjLzQwP2ltZz0xIiwiaWF0IjoxNjk5OTI1MDIxfQ.CXkWgdXYkWqZ7tGGsvqN1gwlklp9HOP9D7gH8bDgUpY",
    },
    {
      id: 5,
      name: "parent",
      email: "parent@gmail.com",
      role: "parent",
      password: "parent@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwibmFtZSI6InBhcmVudCIsImVtYWlsIjoicGFyZW50QGdtYWlsLmNvbSIsInJvbGUiOiJwYXJlbnQiLCJwYXNzd29yZCI6InBhcmVudEAxMjMiLCJzdGF0dXMiOiJZIiwiaXAiOiJodHRwczovL2kucHJhdmF0YXIuY2MvNDA/ImltZyI6Imh0dHBzOi8vaS5wcmF2YXRhci5jYy80MD9pbWc9MSIsImV4cCI6IjEzLTEyLTIwMjUiLCJpYXQiOjE2OTk5MjUwODF9.cJymgoNk5V1UyjtkqzOM7FY3SH_hT8Ppg6dMyOYtIug",
    },
    {
      id: 6,
      name: "Super-admin",
      email: "Superadmin@gmail.com",
      role: "super-admin",
      password: "Superadmin@123",
      status: "Y",
      exp: "13-12-2025",
      themeColor: "red",
      img: "https://i.pravatar.cc/40?img=1",
      tokan:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NiwibmFtZSI6IlN1cGVyLWFkbWluIiwiZW1haWwiOiJTdXBlcmFkbWluQGdtYWlsLmNvbSIsInJvbGUiOiJzdXBlci1hZG1pbiIsInBhc3N3b3JkIjoiU3VwZXJhZG1pbkAxMjMiLCJzdGF0dXMiOiJZIiwiZXhwIjoxNzQ0MzgxNjAwLCJ0aGVtZUNvbG9yIjoicmVkIiwiaW1nIjoiaHR0cHM6Ly9pLnByYXZhdGFyLmNjLzQwP2ltZz0xIiwiaWF0IjoxNjkxMDQ4NjAwfQ._YourTokenSignature_",
    },
  ];
  const login = () => {
    if (!name || !pass) {
      const Message = !name && !pass
        ? "Please enter username and password"
        : !name
        ? "Please enter username"
        : "Please enter password";
  
      notification.open({
        type: "error",
        message: Message,
        className: "",
      });
      return;
    }
    if (name && pass) {
      let obj = {
        name: name,
        password: pass
      }

      let payload = {
        method: "post",
        url: "/auth/login",
        data: obj,
      };
      fetchApi(payload)
        .then((response) => {
          if (response?.data?.token){
            localStorage.setItem(
              process.env.REACT_APP_JWT_TOKEN,
              response.data.token
            );
          }
            
          
          sendNotify("success",response.massage||"successfully Login")
          window.location.href = "/";
        })
        .catch((error) => (
          sendNotify("error",JSON.stringify(error))
         ));
    }    
  };

  const loginPressed = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      login();
    }
  };
  const userNameOnChange = (value) => {
    setName(value);
  };
  const passwordOnChange = (value) => {
    setPass(value);
  };
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Content style={{ display: "flex" }}>
          {/* Left Blue Section */}
          <div
            style={{
              flex: 1,
              backgroundColor: "#005f61",
              borderRadius: "8px",
              margin: "20px",
            }}
          ></div>

          {/* Right Form Section */}
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 50px",
            }}
          >
            <Title level={2} style={{ fontWeight: "bold" }}>
              Sign In
            </Title>
            <Form
              name="signin"
              layout="vertical"
              style={{ maxWidth: "400px", width: "100%" }}
            >
              {/* Email Field */}
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter your email"
                  style={{ width: "400px", border: "none" }}
                  id="username"
                  type="text"
                  onChange={(e) => userNameOnChange(e.target.value)}
                />
              </Form.Item>

              {/* Password Field */}
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Password is required!" },
                  {
                    pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                    message:
                      "Enter valid password",
                  },]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  onKeyDown={loginPressed}
                  className="password"

                  style={{
                    borderRadius: "8px",
                    width: "400px",

                  }}
                  onChange={(e) => passwordOnChange(e.target.value)}
                />
              </Form.Item>

              {/* Remember Me and Forgot Password */}
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Checkbox>Remember me</Checkbox>
                  <Text style={{ cursor: "pointer", color: "#005f61" }}>
                    Forgot Password?
                  </Text>
                </div>
              </Form.Item>

              {/* Submit Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", backgroundColor: "#00535E" }}
                  onClick={login}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
            <Text>
              Don’t have an account? <Link to="/register" />
              <a href="/register" style={{ color: "#00535E" }}>
                Register Here
              </a>
            </Text>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";

import { parseJwt } from "../../helper";

const initialState = {
  isLogged: false,
  authToken: "",
  userDetails: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkLogged: (state) => {
    
      let token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
     
      state.isLogged = token ? true : false;
      state.authToken = token ? token : "";

      console.log(token,"qWERTY")
      state.userDetails = token ? parseJwt(token) : {};
    },
  },
});

export const { checkLogged } = authSlice.actions;

export default authSlice.reducer;
import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Badge,
  Avatar,
  Space,
  notification,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./userManagement.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Noticeopr from "../Component/Notice/Noticeopr.jsx";
import Sidebar from "../layout/base/Sidebar";
import Circularopr from "../Component/circular/Circularopr.jsx";

const { confirm } = Modal;

const allData = [
  {
    key: 1,
    NoticeName: "Retirement Age",
    audience: "Admin",
    publishedOn: "21 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 2,
    NoticeName: "Annual Event",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 4,
    NoticeName: "Exam schedule",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
  {
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },{
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },{
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },{
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },{
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Circular() {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userData, setUserData] = useState(allData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(allData.length);
  }, []);
  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {},
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };
  const columns = [
    {
      title: <input type="checkbox" />,
      dataIndex: "checkbox",
      render: () => <input type="checkbox" />,
    },

    {
      title: "Circular subject",
      dataIndex: "NoticeName",
    },
    {
      title: "Audience",
      dataIndex: "audience",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={status === "published" ? "#00ADC4" : "red"}
          style={{
            border: `1px solid ${status === "Published" ? "#00ADC4" : "red"}`,
            color: status === "Published" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
          }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Published On",
      dataIndex: "publishedOn",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    },
  ];
  const useronChange = async (value) => {
    if (value) {
      let finded = allData.filter((a) => a.role == value);
      setUserData(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserData(allData);
      setTotalcount(allData.length);
    }
  };
  const searchOnChange = (value) => {
    let data = allData.filter((item) =>
      item.NoticeName.toLowerCase().includes(value.toLowerCase())
    );
    setUserData(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  //pagination 
  const objectLength = Object.keys(allData).length;

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <Input
                onChange={(e) => searchOnChange(e.target.value)}
                value={search}
                placeholder="Search by Circular Name"
                prefix={<SearchOutlined />}
                className="seachAccadamicemployee"
              />
              <div className="btnacccon">
                <div className="filterbtn">
                  <FontAwesomeIcon icon={faFilter} size="lg" className="icon" />
                  <p style={{ marginRight: "5px" }}>Filter</p>
                </div>

                <div
                  className="addbtn" 
                  style={{width:'50%'}}
                  onClick={() => openOnChange(null, "add")}
                >
                  <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
                  <p style={{ marginRight: "5px" }}>Add</p>
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={userData}
              pagination={{
                defaultCurrent: 1, 
                total: objectLength, 
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
                showTotal: (total, range) =>(
                  <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
                )
                }}
            />
          </div>
        ) : (
          <Circularopr
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        )}
      </div>
    </>
  );
}

export default Circular;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Badge,
  Avatar,
  Space,
  notification,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './userManagement.css'
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Noticeopr from "../Component/Notice/Noticeopr.jsx";
import Sidebar from "../layout/base/Sidebar";
const { confirm } = Modal;
const allData = [
  {
    key: 1,
    NoticeName: "Retirement Age",
    audience: "Admin",
    publishedOn: "21 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 2,
    NoticeName: "Annual Event",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "21 - Sep - 2024",
    status: "Un Published",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 4,
    NoticeName: "Exam schedule",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
];
function Notices() {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userData, setUserData] = useState(allData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(allData.length);
  }, []);

  const onSelectAllChange = (checked) => {
    if (checked) {
      // Select all rows
      setSelectedRowKeys(userData.map((row) => row.key));
    } else {
      // Deselect all rows
      setSelectedRowKeys([]);
    }
  };

  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() { },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };
  const columns = [
    {
      title: (
        <input
          type="checkbox"
          checked={selectedRowKeys.length === userData.length}
          indeterminate={
            selectedRowKeys.length > 0 &&
            selectedRowKeys.length < userData.length
          }
          onChange={(e) => onSelectAllChange(e.target.checked)}
        />
      ),
      dataIndex: "checkbox",
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedRowKeys.includes(record.key)}
          onChange={() => onRowCheckboxChange(record)}
        />
      ),
    },
    {
      title: "Notice Name",
      dataIndex: "NoticeName",
    },
    {
      title: "Audience",
      dataIndex: "audience",
    },
    {
      title: "Published On",
      dataIndex: "publishedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status.toLowerCase() === "published" ? "#00ADC4" : "red"}`,
            color: status.toLowerCase() === "published" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
            padding: "5px 10px",
          }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          {/* <FontAwesomeIcon icon={faEye} size="lg" style={{ color: "#00ADC4", marginLeft: '8px', marginTop: '6px' }} onClick={() => openOnChange(record, 'view')} /> */}
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    },
  ];

  const searchOnChange = (value) => {
    let data = allData.filter((item) =>
      item.NoticeName.toLowerCase().includes(value.toLowerCase()) 
    );
    setUserData(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  const renderActionUI = () => (
    // <div style={{position:'absolute'}}>
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
    >
      <input type="checkbox" checked readOnly style={{ marginRight: "10px" }} />
      <span style={{ marginRight: "20px" }}>{selectedRowKeys.length} Data</span>
      <span style={{ marginRight: "20px", cursor: "pointer", color: "red" }}>
        Remove
      </span>
    </div>
    // </div>
  );
  const onRowCheckboxChange = (record) => {
    const updatedSelection = selectedRowKeys.includes(record.key)
      ? selectedRowKeys.filter((key) => key !== record.key) // Uncheck if already selected
      : [...selectedRowKeys, record.key]; // Add if not selected

    setSelectedRowKeys(updatedSelection);
  };

  const objectLength = Object.keys(userData).length;

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <div
            className="tablecontainer"
            style={{
              padding: "20px",
              backgroundColor: "#f6f7f9",
              fontFamily: "poppins",
              fontWeight: 400,
            }}
          >
            <div >
              <div className="tableinnercontainer" >
                <Input
                  onChange={(e) => searchOnChange(e.target.value)}
                  value={search}
                  placeholder="Search by Notice Name or Date"
                  prefix={<SearchOutlined />}
                  className="seachAccadamicemployee"
                />
                <div className="btnacccon">
                  <div className="filterbtn">
                    <FontAwesomeIcon
                      icon={faFilter}
                      size="lg"
                      className="icon"
                    />
                    <p style={{ marginRight: "5px" }}>Filter</p>
                  </div>
                  <div
                    className="addbtn"
                    onClick={() => openOnChange(null, "add")}
                  >
                    <FontAwesomeIcon icon={faPlus} size="lg" className="icon" style={{ width: '50%' }} />
                    <p style={{ marginRight: "5px" }}>Add</p>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                  zIndex: "1",
                  backgroundColor: "#FFFF",
                  boxshadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "10px",
                }}
              >
                {selectedRowKeys.length > 0 && renderActionUI()}
              </div>

              <Table
                columns={columns}
                dataSource={userData}
                pagination={{
                  defaultCurrent: 1,
                  total: objectLength,
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '50'],
                  showTotal: (total, range) => (
                    <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
                  )
                }}
                style={{ borderRadius: "10px", backgroundColor: "#F7F7F7" }}
              />
            </div>
          </div>
        ) : (
          <Noticeopr
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        )}
      </div>
    </>
  );
}

export default Notices;

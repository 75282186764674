import React from "react";
import { Form, Input, Button, Select, DatePicker, Tabs, Upload, Row, Col, Card,Typography } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import './Teacherupload.css'
import Flim from '../../Assets/png/Flim.png'

const { TabPane } = Tabs;
const { Title } = Typography;


const Teacherupload = () => {
    return (
        <div
            style={{
                maxWidth: "900px",
                margin: "10px auto",
                padding: "20px",
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
            }}
        >
            <Form layout="vertical">
                {/* Cover Image */}
                <Card
                    style={{
                        marginBottom: "20px",
                        height: "400px",
                        textAlign: "center",
                        border: "2px dashed #d9d9d9",
                        borderRadius: "12px",
                    }}
                >
                    <Upload >
                        <div style={{ border: '2px dashed #d9d9d9', marginTop: '60%', marginLeft: '10px', borderRadius: '50%', height: '100px', width: '100px' }}>
                            <PlusOutlined style={{ fontSize: "32px", color: "#8A8A8A", alignItems: 'center', marginTop: '30%', }} />
                        </div>
                        <p style={{ textAlign: 'center', color: '#8A8A8A' }}>Add your Cover Image</p>

                    </Upload>
                </Card>

                <Row gutter={[16, 16]}>
                    {/* Title */}
                    <Col span={12}>
                        <Form.Item label="Title" name="title">
                            <Input placeholder="Enter title" />
                        </Form.Item>
                    </Col>
                    {/* Uploaded By */}
                    <Col span={12}>
                        <Form.Item label="Uploaded By" name="uploadedBy">
                            <Input placeholder="Enter uploader's name" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* Category */}
                    <Col span={8}>
                        <Form.Item label="Category" name="category">
                            <Select placeholder="Select category">
                                <Select.Option value="math">Math</Select.Option>
                                <Select.Option value="science">Science</Select.Option>
                                <Select.Option value="history">History</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* Upload Date */}
                    <Col span={8}>
                        <Form.Item label="Upload Date" name="uploadDate">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    {/* Select Class */}
                    <Col span={8}>
                        <Form.Item label="Select Class" name="class">
                            <Select placeholder="Select class">
                                <Select.Option value="1">Class 1</Select.Option>
                                <Select.Option value="2">Class 2</Select.Option>
                                <Select.Option value="3">Class 3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Tabs Section */}
                <Tabs defaultActiveKey="about" style={{ marginBottom: "20px" }}>
                    {/* About Tab */}
                    <TabPane tab="About" key="about">
                        <div className="desccontainer">
                            <div className="desinnerccontainer">
                                <Form.Item label="Description" name="description">
                                    <Input placeholder="What you'll learn?" rows={4} />
                                </Form.Item>
                                <Form.Item label="Topics Covered" name="topics">
                                    <Input.Group compact>
                                        <Input placeholder="Definition" style={{ width: '200px', borderRadius: '5px' }} />
                                        <Button icon={<PlusOutlined />} style={{ marginLeft: '20px', borderRadius: '50px',color:'#00ADC4' }} />
                                    </Input.Group>
                                </Form.Item>
                            </div>

                        </div>

                    </TabPane>
                    {/* Resources Tab */}
                    <TabPane tab="Resources" key="resources">
                        <p>Resources Content</p>
                    </TabPane>
                </Tabs>

                {/* Content Section */}
                <Card
                className="desccontainer"
                    style={{
                        marginBottom: "20px",
                        // border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                    }}
                >
                    <Row gutter={[16, 16]}>
                       <Card>
                        <img src={Flim} width='20' height='20'  />
                       </Card>
                       <Title style={{fontSize:'20px',alignItems:'center',marginTop:'20px',marginLeft:'40px'}}>
                        Title
                       </Title>
                       <div style={{background: "linear-gradient(98deg, #00ADC4 -5.78%, #00535E 147.85%)",padding:'2px',marginLeft:'28rem',height:'25px',width:'70px',marginTop:'20px'}}>
                        <p style={{borderRadius:'10px',color:'#fff'}}>+ upload vedio</p>
                       </div>
                    </Row>
                    <hr />
                    <Row gutter={[16, 16]}>
                       <Card></Card>
                    </Row>
                </Card>

                {/* Submit and Cancel Buttons */}
                <Row justify="end" gutter={16}>
                    <Col>
                        <Button type="default">Cancel</Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Teacherupload;

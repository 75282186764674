import React from "react";
import { Empty, Button } from "antd";
import "./EmptyDataPage.css";

const EmptyDataPage = ({ description = "No data available", onReload }) => {
  return (
    <div className="empty-data-container">
      <Empty description={description} className="empty-data" />
      {onReload && (
        <Button type="primary" className="empty-data-button" onClick={onReload}>
          Reload
        </Button>
      )}
    </div>
  );
};

export default EmptyDataPage;

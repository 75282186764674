import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button, Badge, Avatar, Popover, Select, Space, notification, Tag, } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { faFilter, faPlus, faPenToSquare, faEye, faTrash, } from "@fortawesome/free-solid-svg-icons";
// import './userManagement.css'
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import UserManagementedit from "../Component/Usermanagement/Parentedit";
import EmptyDataPage from "../Component/EmptyDataPage";
import Sidebar from "../layout/base/Sidebar";

const { confirm } = Modal;
const allData = [
  {
    key: 1,
    name: "Nivatha Thomas",
    admissionID: "ENG00201",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 2,
    name: "Arjun Reddy",
    admissionID: "ENG00202",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543211",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 3,
    name: "Meera Kapoor",
    admissionID: "ENG00203",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 4,
    name: "Rohan Sharma",
    admissionID: "ENG00204",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543213",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
  {
    key: 5,
    name: "Ananya Iyer",
    admissionID: "ENG00205",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543214",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=5",
  },
  {
    key: 6,
    name: "Vikas Rao",
    admissionID: "ENG00206",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543215",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=6",
  },
  {
    key: 7,
    name: "Priya Desai",
    admissionID: "ENG00207",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543216",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=7",
  },
  {
    key: 8,
    name: "Rahul Nair",
    admissionID: "ENG00208",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543217",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=8",
  },
  {
    key: 9,
    name: "Sneha Patel",
    admissionID: "ENG00209",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543218",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=9",
  },
  {
    key: 10,
    name: "Kiran Gupta",
    admissionID: "ENG00210",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543219",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=10",
  },
  {
    key: 11,
    name: "Aditi Singh",
    admissionID: "ENG00211",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543220",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=11",
  },
  {
    key: 12,
    name: "Vishal Mehta",
    admissionID: "ENG00212",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543221",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=12",
  },
  {
    key: 13,
    name: "Ritu Jain",
    admissionID: "ENG00213",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543222",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=13",
  },
  {
    key: 14,
    name: "Nikhil Saxena",
    admissionID: "ENG00214",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543223",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=14",
  },
  {
    key: 15,
    name: "Pooja Bhatt",
    admissionID: "ENG00215",
    Gradesec: "11-A",
    role: "Admin",
    phoneNumber: "+91 9876543224",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=15",
  },

  {
    key: 16,
    name: "Nivatha Thomas",
    admissionID: "ENG00201",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 17,
    name: "Arjun Reddy",
    admissionID: "ENG00202",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543211",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 18,
    name: "Meera Kapoor",
    admissionID: "ENG00203",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 19,
    name: "Rohan Sharma",
    admissionID: "ENG00204",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543213",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
  {
    key: 20,
    name: "Ananya Iyer",
    admissionID: "ENG00205",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543214",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=5",
  },
  {
    key: 21,
    name: "Vikas Rao",
    admissionID: "ENG00206",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543215",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=6",
  },
  {
    key: 22,
    name: "Priya Desai",
    admissionID: "ENG00207",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543216",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=7",
  },
  {
    key: 23,
    name: "Rahul Nair",
    admissionID: "ENG00208",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543217",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=8",
  },
  {
    key: 24,
    name: "Sneha Patel",
    admissionID: "ENG00209",
    Gradesec: "10-A",
    role: "parent",
    phoneNumber: "+91 9876543218",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=9",
  },
  {
    key: 25,
    name: "Kiran Gupta",
    admissionID: "ENG00210",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543219",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=10",
  },
  {
    key: 26,
    name: "Aditi Singh",
    admissionID: "ENG00211",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543220",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=11",
  },
  {
    key: 27,
    name: "Vishal Mehta",
    admissionID: "ENG00212",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543221",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=12",
  },
  {
    key: 28,
    name: "Ritu Jain",
    admissionID: "ENG00213",
    Gradesec: "11-A",
    role: "Admin",
    phoneNumber: "+91 9876543222",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=13",
  },
  {
    key: 29,
    name: "Nikhil Saxena",
    admissionID: "ENG00214",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543223",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=14",
  },
  {
    key: 30,
    name: "Pooja Bhatt",
    admissionID: "ENG00215",
    Gradesec: "11-A",
    role: "parent",
    phoneNumber: "+91 9876543224",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=15",
  },
];
function Parent({ isEdit }) {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userData, setUserData] = useState(allData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [userDataList, setUserDataList] = useState(allData);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(null);
  const { role } = useParams();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  console.log(excelData, "excelData");

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    haldlerefresh();
  }, [role]);
  console.log(role);
  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() { },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);
    setSelectedDate({});
  };
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  //pagination 
  const objectLength = Object.keys(userDataList).length;



  // Table columns configuration
  const columns = [
    ...(isEdit
      ? [
        {
          title: <input type="checkbox" />,
          dataIndex: "checkbox",
          render: () => <input type="checkbox" />,
        },
      ]
      : []),

    {
      title: "Parent Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
    },
    {
      title: "Student Admission Number",
      dataIndex: "admissionID",
    },
    {
      title: "Grade & Sec",
      dataIndex: "Gradesec",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={status === "Active" ? "#00ADC4" : "red"}
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "red"}`,
            color: status === "Active" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
          }}
        >
          {status}
        </Tag>
      ),
    },
  ];
  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          {/* <FontAwesomeIcon icon={faPenToSquare} size="lg" style={{ color: "#00ADC4", marginLeft: '8px', marginTop: '6px' }} onClick={() => openOnChange(record, 'edit')} /> */}
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    });

  const useronChange = (value) => {
    if (value) {
      const filtered = allData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(allData);
      setTotalcount(allData.length);
    }
  };
  const haldlerefresh = async () => {
    if (role) {
      let finded = allData.filter((a) => a.role == capitalizeFirstLetter(role));
      setUserData(finded);
      setTotalcount(finded.length);
      setUserType(role);
    } else {
      setUserData(allData);
      setTotalcount(allData.length);
    }
  };
  const searchOnChange = (value) => {
    const data = allData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <div className="selectwithbtn">
                <Input
                  onChange={(e) => searchOnChange(e.target.value)}
                  value={search}
                  placeholder="Search by Admission Number"
                  prefix={<SearchOutlined />}
                  className="seachAccadamicemployee"
                />

                <div className="slectwithbtninner">
                  <Select
                    value={userType}
                    onChange={useronChange}
                    className="selectdivstudent"
                    placeholder=""
                    options={[
                      { value: "", label: "Select Grade" },
                      { value: "9-A", label: "9-A" },
                      { value: "9-B", label: "9-B" },
                      { value: "10-A", label: "10-A" },
                      { value: "10-B", label: "10-B" },
                      { value: "11-A", label: "11-A" },
                      { value: "11-B", label: "11-B" },
                      { value: "12-A", label: "12-A" },
                    ]}
                  />
                </div>

                <div className="filterbtns">
                  <FontAwesomeIcon icon={faFilter} size="lg" className="icon" />
                  <p style={{ marginRight: "5px" }}>Filter</p>
                </div>
              </div>
            </div>

            <Table
              columns={columns}
              dataSource={userDataList}
              pagination={{
                defaultCurrent: 1, 
                total: objectLength, 
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
                showTotal: (total, range) => (
                  <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
                )
                }}
              className="tablecontent"
            />
          </div>
        ) : (
          <UserManagementedit
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        )}
      </div>
    </>
  );
}

export default Parent;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { fetchApi } from "../../helper";
import "./subject.css";

const { Option } = Select;

const { confirm } = Modal;

const initialData = [
  {
    key: 46,
    name: "Computer Science",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 45,
    name: " Science",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  }, {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  }, {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  }, {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  }, {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  // Ensure all records have consistent keys.
];

function Subject() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = userData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };

  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );

      let payload = {
        method: "put",
        url: `/subject/${editingRecord.key}`,
        data: editingRecord,
      };
      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "Record updated successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "Record updated field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    } else {
      // Add logic
      const newRecord = { ...values, key: Date.now() };
      setUserData((prev) => [...prev, newRecord]);
      let payload = {
        method: "post",
        url: "/subject",
        data: newRecord,
      };
      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "record added successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "record add field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Subject Name", dataIndex: "name" },
    { title: "Subject Code", dataIndex: "subjectID" },
    { title: "Class", dataIndex: "Grade" },
    { title: "Group", dataIndex: "group" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(initialData).length;


  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          {/* <h1 style={{ fontSize: '20px', fontWeight: 400, marginLeft: '10px' }}>Subject</h1> */}
          <Input
            placeholder="Search by Subject Name"
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            className="seachAccadamicemployee"
          />
          <Select
            className="wholeselect"
            placeholder="Select type"
            style={{ margin: 0, marginLeft: "2rem" }}
            // onChange={handleChange}
            allowClear
            // value={action}
            options={[
              {
                value: "primary",
                label: "primary",
              },
              {
                value: "secondary",
                label: "secondary",
              },
              {
                value: "HigherSecondary",
                label: "HigherSecondary",
              },
            ]}
          />
          {/* <div   className="backgrdbtnstufilter">
                        <FontAwesomeIcon
                            icon={faPlus}
                            onClick={() => setOpenModal(true)}
                            className="iconfilterstu"/>
                    </div> */}
          {/* <div className="addbtnaccsettsubject"> */}
            <div className="btnacccon" style={{ width: "20%" }}>
              <div className="addbtn" onClick={() => setOpenModal(true)}>
                <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
                <p style={{ marginRight: "5px" }}>Add</p>
              </div>
            </div>
          {/* </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{
            defaultCurrent: 1, 
            total: objectLength, 
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50'],
            showTotal: (total, range) => `Showing ${range[1]} of ${total}`,
          }}          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        onOk={() => form.submit()}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h2 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Subject" : "Add Subject"}
        </h2>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Subject Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter subject name!" },
                ]}
              >
                <Input style={{ width: "14rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Subject Code"
                name="subjectID"
                rules={[
                  { required: true, message: "Please enter subject code!" },
                ]}
              >
                <Input style={{ width: "14rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Class"
                name="Grade"
                rules={[{ required: true, message: "Please enter class!" }]}
              >
                <Input style={{ width: "14rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Department"
                name="group"
                rules={[{ required: true, message: "Please enter group!" }]}
              >
                {/* <Select
                                    className="custom-select w-100 ptb-1"
                                    placeholder="Select type"
                                    // onChange={handleChange}
                                    allowClear
                                    // value={action}
                                    options={[
                                        {
                                            value: "Primary",
                                            label: "Primary",
                                        },
                                        {
                                            value: "Secondary",
                                            label: "Secondary",
                                        },
                                        {
                                            value: "Higher Secondary",
                                            label: "Higher Secondary",onChange={handleChange}
                                        },

                                    ]}
                                /> */}
                <Select
                  defaultValue="option1"
                  style={{ width: 200 }}
                  allowClear
                >
                  <Option value="primary"> primary</Option>
                  <Option value="Secondary"> Secondary</Option>
                  <Option value="option3"> Higher Secondary</Option>
                </Select>
                {/* <Input style={{width:'14rem',background: '#D9D9D9'}}/> */}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Subject;

import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Layout, Typography, Avatar, Dropdown, Menu, message } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';
import {checkLogged} from "../../store/auth/authSlice"

const { Header } = Layout;


const Navbar = ({ headerName, userData }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const navigate = useNavigate();
  // Logout handler
  const handleLogout = () => {
    localStorage.removeItem('schoolAuth');
    sessionStorage.clear();
    setIsAuthenticated(false);
    //dispatch(checkLogged());

    message.success('Logged out successfully!');
    // Add your logout functionality here
    console.log('User logged out');
    navigate('/');
  };

  useEffect(() => {
    const authToken = localStorage.getItem('schoolAuth');
    if (!authToken) {
      setIsAuthenticated(false);
      navigate('/'); // Redirect if not authenticated
    }
  }, [navigate]);

  // Dropdown menu items
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        Profile
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      style={{
        background: 'white',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Title Section */}
      <h2
        style={{
          fontSize: '20px',
          color: '#333',
          fontFamily: 'Poppins',
          fontWeight: '600',
        }}
        className="welcomeheader"
      >
        {headerName || 'Welcome back'}
      </h2>

      {/* User Info Section */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Dropdown overlay={menu} trigger={['click']}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              gap: '10px',
            }}
          >
            <Avatar size={40} style={{ backgroundColor: '#87d068' }}>
              {userData.name}
            </Avatar>
            <div style={{ lineHeight: '1.2' }}>
              <Typography.Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                {userData.name}
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {userData.role}
              </Typography.Text>
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;



// import React from "react";
// import "./Navbar.css";
// import robot from '../../Assets/png/robot.png'

// const Navbar = ({ headerName }) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//         height: "70px",
//         backgroundColor: "#f5f7fa", // Optional, tweak to match your design
//         padding: "0 20px",
//         boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for separation
//       }}
//     >
//       {/* Left Section: Header */}
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <h2 style={{ margin: 0, color: "#333", fontWeight: 600 }}>{headerName || "Welcome back"}</h2>
//       </div>

//       {/* Right Section: Profile */}
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "15px", // Space between icons and profile
//         }}
//       >
//         {/* Notification or Action Icons */}
//         <div
//           style={{
//             width: "12px",
//             height: "12px",
//             backgroundColor: "#ddd",
//             borderRadius: "50%",
//           }}
//         ></div>
//         <div
//           style={{
//             width: "12px",
//             height: "12px",
//             backgroundColor: "#ddd",
//             borderRadius: "50%",
//           }}
//         ></div>

//         {/* User Profile Section */}
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             gap: "10px", // Space between image and text
//           }}
//         >
//           <img
//             src={robot} // Replace with the actual profile image URL
//             alt="User"
//             style={{
//               width: "40px",
//               height: "40px",
//               borderRadius: "50%",
//               objectFit: "cover",
//             }}
//           />
//           <div>
//             <p style={{ margin: 0, fontWeight: 600 }}>Samuel</p>
//             <p style={{ margin: 0, fontSize: "12px", color: "#888" }}>Admin</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;

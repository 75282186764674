import { jwtDecode } from 'jwt-decode';
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { notification, Steps } from "antd";
import moment from "moment/moment";

import { store } from "../store/configureStore";
import { checkLogged } from "../store/auth/authSlice";


export const sendNotify = (type, content) => {
  notification.open({
    type: type,
    message: content,
    className: "custom-notification",
  });
};

export const getUserData = () => {
  const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

  if (token) {
    const decoded = jwtDecode(token);
    return decoded;
  } else {
    console.log('No token found');
    return null;
  }
};


export const fetchApi = async (payload) => {
  const response = await axios(payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        sendNotify("error", "Token was expired!");
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN);
        store.dispatch(checkLogged());
      }
      return { error: error };
    });
  return response;
};


export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace("-", "+").replace("_", "/");
  let data = getAtob(base64) ? getAtob(base64) : null;
  let details = {};
  details = { ...JSON.parse(data) };
  return details;
}

export function getAtob(data) {
  try {
    return window.atob(data);
  } catch (e) {
    return false;
  }
}






export const downloadXLSX = (apiData, fileName, headersArray) => {
  // Filter headersArray to include only required fields
  const requiredHeaders = headersArray.filter((header) => header.required);

  // Map apiData to use only required fields
  const formattedData = apiData.map((row) => {
    const newRow = {};
    requiredHeaders.forEach((header) => {
      newRow[header.header] = row[header.name] || "";
    });
    return newRow;
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // Write to Excel format
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save file
  saveAs(data, `${fileName}.xlsx`);
};
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const downloadAsCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};
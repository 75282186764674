import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button, Badge, Avatar, Select, Space, notification,Tag,Row,Col } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Driveropr from '../Component/Driver/driveropr.jsx'
import Sidebar from "../layout/base/Sidebar";
import './Driver.css';
import DriverDetails from "./DriverDetails.jsx";

const { confirm } = Modal;
const allData = [
    { key: 1, name: "Nivatha Thomas", employeeID: "ENG00201", role: "Admin", phoneNumber: "+91 9876543210", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=1" },
    { key: 2, name: "Arjun Reddy", employeeID: "ENG00202", role: "Admin", phoneNumber: "+91 9876543211", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=2" },
    { key: 3, name: "Meera Kapoor", employeeID: "ENG00203", role: "Admin", phoneNumber: "+91 9876543212", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=3" },
    { key: 4, name: "Rohan Sharma", employeeID: "ENG00204", role: "Admin", phoneNumber: "+91 9876543213", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=4" },
    { key: 5, name: "Ananya Iyer", employeeID: "ENG00205", role: "Admin", phoneNumber: "+91 9876543214", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=5" },
    { key: 6, name: "Vikas Rao", employeeID: "ENG00206", role: "Admin", phoneNumber: "+91 9876543215", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=6" },
    { key: 7, name: "Priya Desai", employeeID: "ENG00207", role: "Admin", phoneNumber: "+91 9876543216", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=7" },
    { key: 8, name: "Rahul Nair", employeeID: "ENG00208", role: "Admin", phoneNumber: "+91 9876543217", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=8" },
    { key: 9, name: "Sneha Patel", employeeID: "ENG00209", role: "Teacher", phoneNumber: "+91 9876543218", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=9" },
    { key: 10, name: "Kiran Gupta", employeeID: "ENG00210", role: "Teacher", phoneNumber: "+91 9876543219", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=10" },
    { key: 11, name: "Aditi Singh", employeeID: "ENG00211", role: "Teacher", phoneNumber: "+91 9876543220", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=11" },
    { key: 12, name: "Vishal Mehta", employeeID: "ENG00212", role: "Teacher", phoneNumber: "+91 9876543221", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=12" },
    { key: 13, name: "Ritu Jain", employeeID: "ENG00213", role: "Teacher", phoneNumber: "+91 9876543222", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=13" },
    { key: 14, name: "Nikhil Saxena", employeeID: "ENG00214", role: "Teacher", phoneNumber: "+91 9876543223", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=14" },
    { key: 15, name: "Pooja Bhatt", employeeID: "ENG00215", role: "Admin", phoneNumber: "+91 9876543224", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=15" },

    { key: 16, name: "Nivatha Thomas", employeeID: "ENG00201", role: "Teacher", phoneNumber: "+91 9876543210", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=1" },
    { key: 17, name: "Arjun Reddy", employeeID: "ENG00202", role: "Teacher", phoneNumber: "+91 9876543211", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=2" },
    { key: 18, name: "Meera Kapoor", employeeID: "ENG00203", role: "Admin", phoneNumber: "+91 9876543212", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=3" },
    { key: 19, name: "Rohan Sharma", employeeID: "ENG00204", role: "Teacher", phoneNumber: "+91 9876543213", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=4" },
    { key: 20, name: "Ananya Iyer", employeeID: "ENG00205", role: "Teacher", phoneNumber: "+91 9876543214", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=5" },
    { key: 21, name: "Vikas Rao", employeeID: "ENG00206", role: "Admin", phoneNumber: "+91 9876543215", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=6" },
    { key: 22, name: "Priya Desai", employeeID: "ENG00207", role: "Teacher", phoneNumber: "+91 9876543216", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=7" },
    { key: 23, name: "Rahul Nair", employeeID: "ENG00208", role: "Teacher", phoneNumber: "+91 9876543217", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=8" },
    { key: 24, name: "Sneha Patel", employeeID: "ENG00209", role: "Teacher", phoneNumber: "+91 9876543218", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=9" },
    { key: 25, name: "Kiran Gupta", employeeID: "ENG00210", role: "Admin", phoneNumber: "+91 9876543219", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=10" },
    { key: 26, name: "Aditi Singh", employeeID: "ENG00211", role: "Teacher", phoneNumber: "+91 9876543220", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=11" },
    { key: 27, name: "Vishal Mehta", employeeID: "ENG00212", role: "Teacher", phoneNumber: "+91 9876543221", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=12" },
    { key: 28, name: "Ritu Jain", employeeID: "ENG00213", role: "Admin", phoneNumber: "+91 9876543222", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=13" },
    { key: 29, name: "Nikhil Saxena", employeeID: "ENG00214", role: "Teacher", phoneNumber: "+91 9876543223", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=14" },
    { key: 30, name: "Pooja Bhatt", employeeID: "ENG00215", role: "Teacher", phoneNumber: "+91 9876543224", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=15" },


    { key: 31, name: "Nivatha Thomas", employeeID: "ENG00201", role: "Student", phoneNumber: "+91 9876543210", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=1" },
    { key: 32, name: "Arjun Reddy", employeeID: "ENG00202", role: "Student", phoneNumber: "+91 9876543211", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=2" },
    { key: 33, name: "Meera Kapoor", employeeID: "ENG00203", role: "Student", phoneNumber: "+91 9876543212", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=3" },
    { key: 34, name: "Rohan Sharma", employeeID: "ENG00204", role: "Student", phoneNumber: "+91 9876543213", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=4" },
    { key: 35, name: "Ananya Iyer", employeeID: "ENG00205", role: "Teacher", phoneNumber: "+91 9876543214", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=5" },
    { key: 36, name: "Vikas Rao", employeeID: "ENG00206", role: "Teacher", phoneNumber: "+91 9876543215", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=6" },
    { key: 37, name: "Priya Desai", employeeID: "ENG00207", role: "Teacher", phoneNumber: "+91 9876543216", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=7" },
    { key: 38, name: "Rahul Nair", employeeID: "ENG00208", role: "Teacher", phoneNumber: "+91 9876543217", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=8" },
    { key: 39, name: "Sneha Patel", employeeID: "ENG00209", role: "Teacher", phoneNumber: "+91 9876543218", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=9" },
    { key: 40, name: "Kiran Gupta", employeeID: "ENG00210", role: "Teacher", phoneNumber: "+91 9876543219", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=10" },
    { key: 41, name: "Aditi Singh", employeeID: "ENG00211", role: "Student", phoneNumber: "+91 9876543220", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=11" },
    { key: 42, name: "Vishal Mehta", employeeID: "ENG00212", role: "Teacher", phoneNumber: "+91 9876543221", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=12" },
    { key: 43, name: "Ritu Jain", employeeID: "ENG00213", role: "Student", phoneNumber: "+91 9876543222", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=13" },
    { key: 44, name: "Nikhil Saxena", employeeID: "ENG00214", role: "Teacher", phoneNumber: "+91 9876543223", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=14" },
    { key: 45, name: "Pooja Bhatt", employeeID: "ENG00215", role: "Teacher", phoneNumber: "+91 9876543224", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=15" },

    { key: 46, name: "Nivatha Thomas", employeeID: "ENG00201", role: "Driver", phoneNumber: "+91 9876543210", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=1" },
    { key: 47, name: "Arjun Reddy", employeeID: "ENG00202", role: "Driver", phoneNumber: "+91 9876543211", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=2" },
    { key: 48, name: "Meera Kapoor", employeeID: "ENG00203", role: "Driver", phoneNumber: "+91 9876543212", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=3" },
    { key: 49, name: "Rohan Sharma", employeeID: "ENG00204", role: "Student", phoneNumber: "+91 9876543213", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=4" },
    { key: 50, name: "Ananya Iyer", employeeID: "ENG00205", role: "Driver", phoneNumber: "+91 9876543214", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=5" },
    { key: 51, name: "Vikas Rao", employeeID: "ENG00206", role: "Driver", phoneNumber: "+91 9876543215", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=6" },
    { key: 52, name: "Priya Desai", employeeID: "ENG00207", role: "Driver", phoneNumber: "+91 9876543216", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=7" },
    { key: 53, name: "Rahul Nair", employeeID: "ENG00208", role: "Driver", phoneNumber: "+91 9876543217", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=8" },
    { key: 54, name: "Sneha Patel", employeeID: "ENG00209", role: "Driver", phoneNumber: "+91 9876543218", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=9" },
    { key: 55, name: "Kiran Gupta", employeeID: "ENG00210", role: "Student", phoneNumber: "+91 9876543219", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=10" },
    { key: 56, name: "Aditi Singh", employeeID: "ENG00211", role: "Student", phoneNumber: "+91 9876543220", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=11" },
    { key: 57, name: "Vishal Mehta", employeeID: "ENG00212", role: "Driver", phoneNumber: "+91 9876543221", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=12" },
    { key: 58, name: "Ritu Jain", employeeID: "ENG00213", role: "Driver", phoneNumber: "+91 9876543222", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=13" },
    { key: 59, name: "Nikhil Saxena", employeeID: "ENG00214", role: "Driver", phoneNumber: "+91 9876543223", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=14" },
    { key: 60, name: "Pooja Bhatt", employeeID: "ENG00215", role: "Driver", phoneNumber: "+91 9876543224", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=15" },

    { key: 61, name: "Nivatha Thomas", employeeID: "ENG00201", role: "Parent", phoneNumber: "+91 9876543210", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=1" },
    { key: 62, name: "Arjun Reddy", employeeID: "ENG00202", role: "Parent", phoneNumber: "+91 9876543211", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=2" },
    { key: 63, name: "Meera Kapoor", employeeID: "ENG00203", role: "Parent", phoneNumber: "+91 9876543212", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=3" },
    { key: 64, name: "Rohan Sharma", employeeID: "ENG00204", role: "Parent", phoneNumber: "+91 9876543213", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=4" },
    { key: 65, name: "Ananya Iyer", employeeID: "ENG00205", role: "Parent", phoneNumber: "+91 9876543214", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=5" },
    { key: 66, name: "Vikas Rao", employeeID: "ENG00206", role: "Parent", phoneNumber: "+91 9876543215", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=6" },
    { key: 67, name: "Priya Desai", employeeID: "ENG00207", role: "Parent", phoneNumber: "+91 9876543216", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=7" },
    { key: 68, name: "Rahul Nair", employeeID: "ENG00208", role: "Student", phoneNumber: "+91 9876543217", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=8" },
    { key: 69, name: "Sneha Patel", employeeID: "ENG00209", role: "Parent", phoneNumber: "+91 9876543218", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=9" },
    { key: 70, name: "Kiran Gupta", employeeID: "ENG00210", role: "Parent", phoneNumber: "+91 9876543219", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=10" },
    { key: 71, name: "Aditi Singh", employeeID: "ENG00211", role: "Parent", phoneNumber: "+91 9876543220", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=11" },
    { key: 72, name: "Vishal Mehta", employeeID: "ENG00212", role: "Parent", phoneNumber: "+91 9876543221", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=12" },
    { key: 73, name: "Ritu Jain", employeeID: "ENG00213", role: "Student", phoneNumber: "+91 9876543222", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=13" },
    { key: 74, name: "Nikhil Saxena", employeeID: "ENG00214", role: "Parent", phoneNumber: "+91 9876543223", status: "Active", avatarUrl: "https://i.pravatar.cc/40?img=14" },
    { key: 75, name: "Pooja Bhatt", employeeID: "ENG00215", role: "Parent", phoneNumber: "+91 9876543224", status: "Inactive", avatarUrl: "https://i.pravatar.cc/40?img=15" },





];

function Drivers() {
    const [userData, setUserData] = useState(allData)
   
    return (
    <>
    <div>
              <DriverDetails userData={userData} isEdit={false} count={userData.length} />
    </div>
   
   </>
   
    );
}

export default Drivers;

// import React, { useState, useEffect } from "react";
// import { Table, Pagination, Select, Avatar, Tag } from "antd";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilter, faPlus, faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
// import './Driver.css';

// const mockFetchData = (page, pageSize) => {
//     // Mocking server-side data fetching
//     const totalData = Array.from({ length: 105 }, (_, index) => ({
//         key: index + 1,
//         name: `User ${index + 1}`,
//         employeeID: `EMP00${index + 1}`,
//         role: index % 2 === 0 ? "Admin" : "User",
//         phoneNumber: `+91 987654${index}`,
//         status: index % 2 === 0 ? "Active" : "Inactive",
//         avatarUrl: `https://i.pravatar.cc/40?img=${index % 70}`,
//     }));

//     const start = (page - 1) * pageSize;
//     const end = start + pageSize;
//     return {
//         total: totalData.length,
//         data: totalData.slice(start, end),
//     };
// };

// const AdvancedPaginationTable = () => {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageSize, setPageSize] = useState(10); // Items per page
//     const [totalItems, setTotalItems] = useState(0);
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         // Fetch data whenever currentPage or pageSize changes
//         const { total, data } = mockFetchData(currentPage, pageSize);
//         setTotalItems(total);
//         setData(data);
//     }, [currentPage, pageSize]);

//     const handlePageChange = (page, pageSize) => {
//         setCurrentPage(page);
//         setPageSize(pageSize);
//     };

//     const handlePageSizeChange = (newSize) => {
//         setPageSize(newSize);
//         setCurrentPage(1); // Reset to first page
//     };
//     return (
//         <div>
//             {/* <Table
//                 columns={columns}
//                 dataSource={data}
//                 pagination={false}
//                 rowKey="key"
//             /> */}
//             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
                
//                 <Pagination
//                     current={currentPage}
//                     pageSize={pageSize}
//                     total={totalItems}
//                     onChange={handlePageChange}
//                     showSizeChanger={false} // Using custom page size dropdown
//                     showQuickJumper
//                     style={{ flex: 1, textAlign: "right" }}
//                 />
//                 <Select
//                     value={pageSize}
//                     onChange={handlePageSizeChange}
//                     options={[
//                         { value: 5, label: "5 items/page" },
//                         { value: 10, label: "10 items/page" },
//                         { value: 20, label: "20 items/page" },
//                         { value: 50, label: "50 items/page" },
//                     ]}
//                     style={{ width: "150px" }}
//                 />
//             </div>
//         </div>
//     );
// };

// export default AdvancedPaginationTable;

// import React from 'react';
// import { Table, Avatar, Tag, Typography, Select } from 'antd';

// const { Title } = Typography;
// const { Option } = Select;

// const data = [
//   {
//     key: '1',
//     profile: 'https://randomuser.me/api/portraits/men/1.jpg',
//     name: 'Samuel M S',
//     subject: 'Mathematics',
//     status: 'Active',
//   },
//   {
//     key: '2',
//     profile: 'https://randomuser.me/api/portraits/men/1.jpg',
//     name: 'Samuel M S',
//     subject: 'Mathematics',
//     status: 'Active',
//   },
//   // Add more data as needed
// ];

// const columns = [
//   {
//     title: 'Profile',
//     dataIndex: 'profile',
//     key: 'profile',
//     render: (text) => <Avatar src={text} size={48} />,
//   },
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Subject',
//     dataIndex: 'subject',
//     key: 'subject',
//   },
//   {
//     title: 'Status',
//     dataIndex: 'status',
//     key: 'status',
//     render: (status) => (
//       <Tag color="cyan" style={{ borderRadius: '16px', padding: '0 12px' }}>
//         {status}
//       </Tag>
//     ),
//   },
// ];

// const DepartmentTable = () => {
//   return (
//     <div style={{ padding: '24px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
//         <Title level={5} style={{ margin: 0, }}>Head of Department</Title>
//         <Select placeholder="Choose Department" style={{ width: 180 }}>
//           <Option value="mathematics">Mathematics</Option>
//           <Option value="science">Science</Option>
//           <Option value="english">English</Option>
//         </Select>
//       </div>

//       <Table
//         columns={columns}
//         dataSource={data}
//         pagination={false}
//         style={{ borderRadius: '8px', overflow: 'hidden',background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)' }}
//       />
//     </div>
//   );
// };

// export default DepartmentTable;

import React from 'react';
import { Table, Avatar, Tag, Typography, Select } from 'antd';
import './Deparmenthead.css'

const { Title } = Typography;
const { Option } = Select;

const data = [
  {
    key: '1',
    profile: 'https://randomuser.me/api/portraits/men/1.jpg',
    name: 'Samuel M S',
    subject: 'Mathematics',
    status: 'Active',
  },
  {
    key: '2',
    profile: 'https://randomuser.me/api/portraits/men/1.jpg',
    name: 'Samuel M S',
    subject: 'Mathematics',
    status: 'Active',
  },
  // Add more data as needed
];

const columns = [
  {
    title: 'Profile',
    dataIndex: 'profile',
    key: 'profile',
    render: (text) => <Avatar src={text} size={48} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <Tag color="cyan" style={{ borderRadius: '16px', padding: '0 12px' }}>
        {status}
      </Tag>
    ),
  },
];

const DepartmentTable = () => {
  return (
    <div style={{ padding: '24px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',height:'400px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Title level={5} style={{ margin: 0}}>Head of Department</Title>
        <Select placeholder="Choose Department" style={{ width: 180 }}>
          <Option value="mathematics">Mathematics</Option>
          <Option value="science">Science</Option>
          <Option value="english">English</Option>
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
        className='custom-table table'
        style={{ borderRadius: '8px', overflow: 'hidden' }}
      />
    </div>
  );
};

export default DepartmentTable;

import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button, Badge, Avatar, Select, Space, notification, Tag, Row, Col, Popover, } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus, faPenToSquare, faEye, faTrash, } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../layout/base/Sidebar";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Academicopr from "../Component/Acadamicemployeecev/Acadamicopr";
import EmptyDataPage from "../Component/EmptyDataPage";

const { confirm } = Modal;



function Acadamicemployee({ userData, isEdit, count }) {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userArray, setUserArray] = useState(userData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const [excelData, setExcelData] = useState(null);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(null);

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };
  const objectLength = Object.keys(userArray).length;

  console.log(objectLength, "ufcytfyiv");
  useEffect(() => {
    setTotalcount(count);
  }, [count]);
  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };

  // Table columns configuration
  const columns = [
    ...(isEdit
      ? [
        {
          title: <input type="checkbox" />,
          dataIndex: "checkbox",
          render: () =>
            <input type="checkbox"
            />,
        },
      ]
      : []),

    {
      title: "Teacher Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
    },
    {
      title: "EmployeeID",
      dataIndex: "employeeID",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={status === "Active" ? "#00ADC4" : "red"}
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "red"}`,
            color: status === "Active" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
          }}
        >
          {status}
        </Tag>
      ),
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    });
  const useronChange = async (value) => {
    if (value) {
      let finded = userData.filter((a) => a.role == value);
      setUserArray(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserArray(userData);
      setTotalcount(userData.length);
    }
  };

  const handleCheckboxChange = (record) => {
    const selectedKeys = [...selectedRows];
    if (selectedKeys.includes(record.key)) {
      // Deselect the row
      setSelectedRows(selectedKeys.filter((key) => key !== record.key));
    } else {
      // Select the row
      setSelectedRows([...selectedKeys, record.key]);
    }
  };
  const searchOnChange = (value) => {
    let data = userData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setUserArray(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  //bulk upload
  const renderOptions = () => (
    <div className="certificate-links">
      <button onClick={() => openOnChange(null, "add")} className="manualAdd">Add Manual </button>
      <button onClick={() => setOpenUploaddataListing(true)} className="bulkAdd">
        Bulk Upload
      </button>
    </div>
  );

  const handleOk = () => {
    try {
      if (excelData.length) {
        //api
        setExcelData([]);

        notification.open({
          type: "success",
          message: "data added successfuly",
          className: "",
        });
      } else {
        notification.open({
          type: "error",
          message: "No data",
          className: "",
        });
      }
      setOpenUploaddataListing(false);
    } catch (e) {
      notification.open({
        type: "error",
        message: e.message,
        className: "",
      });
    }
  };
  const handleCancel = () => {
    setExcelData([]);

    setOpenUploaddataListing(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const xlsxColumns = [
    {
      title: "Teacher Name",
      dataIndex: "Name",
    },

    {
      title: "Employee ID",
      dataIndex: "EMPID",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "Phone Number",
      dataIndex: "MobileNumber",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
  ];
  return (
    <>
      {!editModuleOpen ? (
        <div className="tablecontainer" >
          <div className="tableinnercontainer" >
            <Input
              onChange={(e) => searchOnChange(e.target.value)}
              value={search}
              placeholder="Search by Employee Name or ID"
              prefix={<SearchOutlined />}
              className="seachAccadamicemployee"
            />
            <div className="btnacccon" >
              <div className="filterbtn" >
                <FontAwesomeIcon icon={faFilter} size="lg" className="icon" />
                <p style={{ marginRight: "5px" }}>Filter</p>
              </div>
              {!isEdit ? (
                <div >
                  <Popover placement="bottom" content={renderOptions()} >
                    <div className="addbtn" >
                      <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
                      <p style={{ marginRight: "5px" }}>Add</p>

                    </div>
                  </Popover>
                </div>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={userArray}
            pagination={{
              defaultCurrent: 1,
              total: objectLength,
              showSizeChanger: true,
              align: "center",
              pageSizeOptions: ['5', '10', '20', '50'],
              showTotal: (total, range) => (
                <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
              )
            }}
            className="tablecontent"
          />
        </div>
      ) : (
        <Academicopr
          record={selectedDate}
          onClose={onClose}
          type={teb}
          selectedId={selectedId}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
          }}
        />
      )}
      <Modal
        title={"Uploaddata Listing"}
        className=""
        open={openUploaddataListing}
        width={1000}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
      >
        <>
          <input type="file" accept=".xlsx" onChange={handleFileUpload} />
          {excelData?.length ? (
            <Table
              columns={xlsxColumns}
              dataSource={excelData}
              pagination={{
                pageSize: 10,
                defaultCurrent: 1,
                total: xlsxColumns.length,
              }}
              style={{ borderRadius: "8px" }}
            />
          ) : (
            <EmptyDataPage description="No records found" />
          )}
        </>
      </Modal>
    </>
  );
}

export default Acadamicemployee;

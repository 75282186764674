import React, { useState } from "react";
import { Card, Row, Col, Input, notification, Button, Typography, Tag } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import courseheader from '../../Assets/png/courseheader.png';
import './Course.css'
import { P } from "@antv/g2plot";
import Viewcourse from "./Viewcourse";

const { Title, Paragraph } = Typography;

const Coursemanagement = () => {
  const [editModuleOpen, seteditModuleOpen] = useState(false)
  const [selectedId, setselectedId] = useState('')
  const [selectedDate, setSelectedDate] = useState({})
  const [teb, setTeb] = useState('')
  const [view, setView] = useState(false); // State to manage view toggle
  const [status, setStatus] = useState('')


  const courses = [
    {
      id: 1,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 2,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    },
    {
      id: 3,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    }, {
      id: 4,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 5,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 6,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 7,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    }, {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    }, {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description: "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "pending",
    },

    // Add more courses here...
  ];
  const openOnChange = (value, teb) => {
    if (teb == 'view-yet') {

      setStatus('pending')
      setselectedId(value?.key)
      seteditModuleOpen(true)
      setTeb(teb)
      setSelectedDate(value)
    }
    if (teb === "view") {
      setStatus('Approved')
      setselectedId(value?.key)
      seteditModuleOpen(true)
      setTeb(teb)
      setSelectedDate(value)
    }
  }

  const deleteonClick = () => {
    notification.open({
      type: 'success',
      message: "data deleted",
      className: ""
    })
  }
  const onClose = () => {
    setselectedId('')
    seteditModuleOpen(false)
    setSelectedDate({})
  }

  return (
    <div>
      {!editModuleOpen ?

        <div>

          <div style={{ padding: "20px", width: "100%", fontFamily: "poppins" }}>
            {/* Filter Section */}
            <Row gutter={[16, 16]}>
              <Col xs={4} sm={4} md={4} lg={6}>
                <Input placeholder="Department Head" style={{ width: "100%", background: '#D9D9D9' }} />
              </Col>
              <Col xs={4} sm={4} md={4} lg={6}>
                <Input placeholder="Department" style={{ width: "100%", background: '#D9D9D9' }} />
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Row style={{ marginTop: "16px", flex: 1 }} gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={8}>
                  <Input
                    placeholder="Search by Employee Name or ID"
                    prefix={<SearchOutlined />}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  // border: 'solid 1px red',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#ECF0F3',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  marginLeft: 'auto', // Moves the filter div to the end
                  padding: '8px',
                  borderRadius: '4px',
                }}
              >
                <FontAwesomeIcon icon={faFilter} size="lg" style={{ color: '#00ADC4', marginRight: '5px' }} />
                <p style={{ margin: 0 }}>Filter</p>
              </div>
            </div>



            {/* Approved Courses Section */}
            <div style={{ marginTop: "20px", width: "100%" }}>
              <Row>
                <Col xs={24} lg={24}>
                  <Title level={4} className="cardtype" style={{ marginBottom: "16px" }}>
                    Approved Courses
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto", // Enables horizontal scrolling
                      scrollSnapType: "x mandatory", // Ensures smooth scrolling
                      gap: "16px", // Space between cards
                      padding: "8px 0",
                      width: '100%',
                      // border: 'solid 1px red',
                      scrollbarWidth: "none", // Hides scrollbar in Firefox
                    }}
                    className="card-slider"
                  >
                    {courses
                      .filter((course) => course.status === "Approved")
                      .map((course) => (
                        <Col lg={8}>
                          <Card
                            key={course.id}
                            hoverable
                            style={{
                              flex: "0 0 auto", // Prevents shrinking; maintains size
                              // width: "350px",
                              borderRadius: "8px",
                              overflow: "hidden",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              scrollSnapAlign: "start", // Aligns card neatly during scroll
                            }}
                            cover={
                              <img
                                alt={`${course.title} Thumbnail`}
                                src={courseheader}
                                style={{
                                  height: "180px",
                                  objectFit: "cover",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                }}
                              />
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between", // Ensures proper spacing between elements
                                marginBottom: "8px",
                                width: "100%",
                              }}
                            >
                              <Title
                                level={5}
                                style={{ margin: "0", color: "#00ADC4", flex: "1" }} // Start of flex
                              >
                                {course.title}
                              </Title>
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "10px",
                                  color: "#ADADAD",
                                  textAlign: "center",
                                  flex: "1", // Center of flex
                                }}
                              >
                                {course.level}
                              </p>
                              <div
                                className="statusdiv"
                                style={{
                                  textAlign: "right",
                                  padding: '4px',
                                  color: course.status === "Approved" ? "#00ADC4" : "gold",

                                }}
                              >
                                {course.status}
                              </div>
                            </div>
                            <div style={{ display: "flex", fontFamily: "Poppins" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                }}
                              >
                                <i
                                  className="fas fa-cloud-upload-alt"
                                  style={{
                                    marginRight: "8px",
                                    color: "#8c8c8c",
                                  }}
                                ></i>
                                <span style={{ marginLeft: "5px" }}>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {course.teacher}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                  marginLeft: "20px",
                                }}
                              >
                                <i className="fas fa-calendar-alt"></i>
                                <span style={{ marginLeft: "5px" }}>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {course.date}
                                </span>
                              </div>
                            </div>
                            <Paragraph className="courseparadesc">
                              {course.description}
                            </Paragraph>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                              <Button
                                type="primary"
                                className="viewbtncourse"
                                style={{ borderRadius: "4px" }}
                                onClick={() => openOnChange(null, "view")}
                              >
                                View
                              </Button>
                              <Button
                                style={{ borderRadius: "4px", marginLeft: "8px" }}
                                className="cancelbtncourse"
                              >
                                Delete
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </div>
                </Col>
              </Row>
            </div>



            <div style={{ marginTop: "20px", width: '100%' }}>
              <Row>
                <Col lg={24}>

                  <Title level={4} className="cardtype">
                    Courses Yet to be Approved
                  </Title>


                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      scrollSnapType: "x mandatory",
                      gap: "16px",
                      padding: "8px 0",
                      scrollbarWidth: "none", // Hides scrollbar in Firefox
                    }}
                    className="card-slider"
                  >
                    {courses
                      .filter(course => course.status !== "Approved")
                      .map((course) => (
                        <Card
                          key={course.id}
                          hoverable
                          style={{
                            flex: "0 0 auto",
                            width: "350px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            scrollSnapAlign: "start",
                          }}
                          cover={
                            <img
                              alt={`${course.title} Thumbnail`}
                              src={courseheader}
                              style={{
                                height: "180px",
                                objectFit: "cover",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                              }}
                            />
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between", // Ensures spacing between elements
                              marginBottom: "8px",
                              width: "100%", // Ensures it spans the full width
                            }}
                          >
                            {/* Title at the start */}
                            <Title
                              level={5}
                              style={{
                                margin: "0",
                                color: "#00ADC4",
                                flex: "1", // Occupies starting position
                              }}
                            >
                              {course.title}
                            </Title>

                            {/* Level in the center */}
                            <p
                              style={{
                                margin: "0",
                                fontSize: "10px",
                                color: "#ADADAD",
                                textAlign: "center",
                                flex: "1", // Center position
                              }}
                            >
                              {course.level}
                            </p>

                            {/* Status at the end */}
                            <div
                              style={{
                                textAlign: "right",
                                color: course.status === "Approved" ? "#00ADC4" : "gold",
                               padding:'4px'
                              }}
                              className="statusdiv"
                            >
                              {course.status}
                            </div>
                          </div>

                          <div style={{ display: "flex", fontFamily: "Poppins" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <i
                                className="fas fa-cloud-upload-alt"
                                style={{
                                  marginRight: "8px",
                                  color: "#8c8c8c",
                                }}
                              ></i>
                              <span style={{ marginLeft: "5px" }}>-</span>
                              <span
                                style={{
                                  color: "#595959",
                                  marginLeft: "5px",
                                }}
                              >
                                {course.teacher}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                                marginLeft: "20px",
                              }}
                            >
                              <i className="fas fa-calendar-alt"></i>
                              <span style={{ marginLeft: "5px" }}>-</span>
                              <span
                                style={{
                                  color: "#595959",
                                  marginLeft: "5px",
                                }}
                              >
                                {course.date}
                              </span>
                            </div>
                          </div>
                          <Paragraph className="courseparadesc">
                            {course.description}
                          </Paragraph>
                          <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                              type="primary"
                              className="viewbtncourse"
                              style={{ borderRadius: "4px" }}
                              onClick={() => openOnChange(null, 'view-yet')}
                            >
                              View
                            </Button>
                            <Button
                              style={{ borderRadius: "4px" }}
                              className="cancelbtncourse"

                            >
                              Delete
                            </Button>
                          </div>
                        </Card>
                      ))}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        :
        <div>
          <Viewcourse status={status} onClose={onClose} />
        </div>

      }
    </div>

  );
};

export default Coursemanagement;

import React, { useState } from "react";
import { Tabs, Card, Row, Col, Typography, List } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
// import "./Eventmanage.css";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const Eventmanage = () => {
  const [activeTab, setActiveTab] = useState("upcoming");

  const events = [
    {
      day: "Wed",
      date: "23",
      title: "Parents Meeting",
      time: "9:30 AM - 12:00 PM",
    },
    {
      day: "Wed",
      date: "23",
      title: "Science Expo",
      time: "9:30 AM - 12:00 PM",
    }, {
      day: "Wed",
      date: "23",
      title: "Annual day",
      time: "9:30 AM - 12:00 PM",
    }
    // Add more events as needed
  ];

  const renderEventItem = (event) => (
    <div
      style={{
        borderRadius: "5px",
        marginBottom: "15px",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
        border: "1px solid #F0F0F0",
        overflowY: "auto",
        overflowX: "hidden",
        // border: 'solid 1px red',
        marginLeft: '5px',
        height: '100px'

      }}
    >
      <Row gutter={16}>
        {/* Date Section */}
        <Col
          span={5}
          style={{
            textAlign: "center",
            // backgroundColor: "#E6F7FF",
            borderRadius: "8px",
            padding: "2px",
            marginLeft: '5px',
            // border: 'solid 1px blue',
            height:'100%',
          }}
        >
          <Text
            strong
            style={{
              color: "#00ADC4",
              marginBottom: "5px",


            }}
          >
            {event.day}
          </Text>
          <br />
          <Text
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#595959",
            }}
          >
            {event.date}
          </Text>
        </Col>
        {/* Event Details */}
        <Col span={18}>
          <Title
            level={5}
            style={{
              marginBottom: "4px",
              color: "#262626",
              // border: 'solid 1px blue',

            }}
          >
            {event.title}
          </Title>
          <Text style={{ color: "#8C8C8C" }}>
            <ClockCircleOutlined
              style={{
                marginRight: "6px",
                color: "#1890FF",
              }}
            />
            {event.time}
          </Text>
        </Col>
      </Row>
    </div>
  );

  return (
    <div
      className="wholecontainer"
      style={{
        margin: "0 auto",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        maxWidth: "400px",
        padding: "5px",
        msoverflowstyle: "none",
        scrollbarwidth: "none"
      }}
    >
      <Title
        level={4}
        style={{
          textAlign: "center",
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        Event
      </Title>
      {/* Tabs for Upcoming and Cancelled */}
      <div className="eventTabContainer">
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          centered
          tabBarStyle={{
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          <TabPane tab="Upcoming" key="upcoming">
            <List
              dataSource={events}
              renderItem={(item) => renderEventItem(item)}
              style={{
                maxHeight: "200px",
                overflowY: "auto", // Only vertical scrolling
                paddingRight: "10px", // Adjust padding
                // Hides horizontal scrolling

              }}
              className="custom-scroll"

            />
          </TabPane>
          <TabPane tab="Cancelled" key="cancelled">
            <p
              style={{
                textAlign: "center",
                color: "#8C8C8C",
                marginTop: "20px",
              }}
            >
              No cancelled events
            </p>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Eventmanage;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './subject.css'

const { confirm } = Modal;

const initialData = [
  {
    key: 46,
    vehicleno: "TN33 BL3354",
    Description: "onmi",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },{
    key: 46,
    vehicleno: "TN33 BL3354",
    Totalsections: "7",
    Department: "primary",
    Description: "omni",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
];

function Class() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = initialData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };

  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );
      notification.success({ message: "Record updated successfully!" });
    } else {
      // Add logic
      const newRecord = { ...values, key: Date.now() };
      setUserData((prev) => [...prev, newRecord]);
      notification.success({ message: "Record added successfully!" });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Vehicle NO", dataIndex: "vehicleno" },
    { title: "Description", dataIndex: "Description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];
  const objectLength = Object.keys(initialData).length;


  return (
    <>
      <div className="tablecontainer">
        <div  className="tableinnercontainer">
          {/* <h1 style={{ fontSize: '20px', fontWeight: 400, marginLeft: '10px' }}>Vehicle</h1> */}
          <Input
            placeholder="Search by Vehicle"
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            className="seachAccadamicemployee"
          />
          {/* <Select
                        className="custom-select w-100 ptb-1"
                        placeholder="Select type"
                        // onChange={handleChange}
                        allowClear
                        style={{  height: '20px', // Control the height
                            lineHeight: '40px', // Adjust text alignment
                             }}
                        // value={action}
                        options={[
                            {
                                value: "primary",
                                label: "primary",
                            },
                            {
                                value: "secondary",
                                label: "secondary",
                            },
                            {
                                value: "HigherSecondary",
                                label: "HigherSecondary",
                            },
                        ]}
                    /> */}
          {/* <div className="addbtnaccsett"> */}
            <div className="btnacccon" style={{ width: "20%" }}>
              <div className="addbtn" onClick={() => setOpenModal(true)}>
                <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
                <p style={{ marginRight: "5px" }}>Add</p>
              </div>
            </div>
          {/* </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{
            defaultCurrent: 1, 
            total: objectLength, 
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50'],
            showTotal: (total, range) => `Showing ${range[1]} of ${total}`,
          }}          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
      </div>

      <Modal
        // title={editingRecord ? "Edit Subject" : "Add Subject"}
        open={openModal}
        onCancel={resetModalState}
        onOk={() => form.submit()}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h2 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Vehicle" : "Add Vehicle"}
        </h2>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Vehicle"
                name="vehicleno"
                rules={[{ required: true, message: "Please enter Vehicle!" }]}
              >
                <Input style={{ width: "14rem", background: "#EBEBEB" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Department"
                name="Description"
                rules={[
                  { required: true, message: "Please enter Department!" },
                ]}
              >
                <Input style={{ width: "30rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Class;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './subject.css'

const { confirm } = Modal;

const initialData = [
  {
    key: 46,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 47,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },{
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },{
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },{
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },      
];

function Department() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = initialData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };

  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );
      notification.success({ message: "Record updated successfully!" });
    } else {
      // Add logic
      const newRecord = { ...values, key: Date.now() };
      setUserData((prev) => [...prev, newRecord]);
      notification.success({ message: "Record added successfully!" });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Department Name", dataIndex: "Departmentname" },
    { title: "Description", dataIndex: "Description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(initialData).length;

  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          {/* <h1 style={{ fontSize: '20px', fontWeight: 400, marginLeft: '10px' }}>Department</h1> */}
          <Input
            placeholder="Search Subjectcode or name"
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            className="seachAccadamicemployee"
          />
          <div className="btnacccon" style={{ width: "20%" }}>
            <div className="addbtn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="lg" className="icon" />
              <p style={{ marginRight: "5px" }}>Add</p>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{
            defaultCurrent: 1, 
            total: objectLength, 
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50'],
            showTotal: (total, range) => `Showing ${range[1]} of ${total}`,
          }}          
          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        onOk={() => form.submit()}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h2 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Department" : "Add Department"}
        </h2>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Depatment Name"
                name="Departmentname"
                rules={[
                  { required: true, message: "Please enter Depatment Name!" },
                ]}
              >
                <Input style={{ width: "14rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Description"
                name="Description"
                rules={[
                  { required: true, message: "Please enter Description!" },
                ]}
              >
                <Input style={{ width: "30rem", background: "#D9D9D9" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Department;

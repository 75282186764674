import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {  faFilter,  faPlus,  faPenToSquare,  faEye,  faTrash,} from "@fortawesome/free-solid-svg-icons";
import {  Modal,  Table,  Input,  Button,  Badge,  Avatar,  Select,  Space,  notification,  Tag,  Row,  Col,  Pagination, Popover,} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  SearchOutlined,  PlusOutlined,  EditOutlined,  DeleteOutlined,} from "@ant-design/icons";
import Studentopr from "../Component/Student/studentopr.jsx";
const { confirm } = Modal;

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    uniqueno: "12634",
    role: "Teacher",
    Gradesec: "9-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    Gradesec: "10-B",
    uniqueno: "129934",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "1234",
    Gradesec: "10-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 50,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    uniqueno: "123004",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 51,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-B",
    uniqueno: "12300004",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 52,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
];

function Studentdetails({ userData, isEdit, count }) {
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userDataList, setUserDataList] = useState(alldatas);
  const [userArray, setUserArray] = useState();
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState(alldatas);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [total, setTotal] = useState(alldatas.length);

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(alldatas.length);
  }, []);
  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() { },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to the first page
  };

  const searchOnChange = (value) => {
    const data = alldatas.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };
//pagination 
  const objectLength = Object.keys(userDataList).length;


  const columns = [
    ...(isEdit
      ? [
        {
          title: <input type="checkbox" />,
          dataIndex: "checkbox",
          render: () => <input type="checkbox" />,
        },
      ]
      : []),

    {
      title: "Student Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
    },
    {
      title: "Unique No",
      dataIndex: "uniqueno",
    },
    {
      title: "Admission NO",
      dataIndex: "employeeID",
    },
    {
      title: "Grade&sec",
      dataIndex: "Gradesec",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={status === "Active" ? "#00ADC4" : "red"}
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "red"}`,
            color: status === "Active" ? "#00ADC4" : "red",
            backgroundColor: "transparent",
            borderRadius: "50px",
          }}
        >
          {status}
        </Tag>
      ),
    },
  ];
  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    });
  const useronChange = async (value) => {
    if (value) {
      let finded = alldatas.filter((a) => a.Gradesec == value);
      setUserDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserDataList(alldatas);
      setTotalcount(alldatas.length);
    }
  };

  return (
    <div>
      {!editModuleOpen ? (
        <div className="tablecontainer">
          <div className="tableinnercontainer">
            <Input
              onChange={(e) => searchOnChange(e.target.value)}
              value={search}
              placeholder="Search by Student Name or ID"
              prefix={<SearchOutlined />}
              className="seachAccadamicemployee"
            />
            <div
              className="wholeselect"
              style={{ margin: 0}}
            >
              <Select
                value={userType}
                onChange={useronChange}
                className="selectdiv"
                allowClear
                style={{ border: "none" }}
                placeholder="Select Grade"
                options={[
                  {
                    value: "",
                    label: "Select Grade",
                  },
                  {
                    value: "9-A",
                    label: "9-A",
                  },
                  {
                    value: "9-B",
                    label: "9-B",
                  },
                  {
                    value: "10-A",
                    label: "10-A",
                  },
                  {
                    value: "10-B",
                    label: "10-B",
                  },
                  {
                    value: "11-A",
                    label: "11-A",
                  },
                  {
                    value: "11-B",
                    label: "11-B",
                  },
                  {
                    value: "12-A",
                    label: "12-A",
                  },
                ]}
              />
            </div>
            <div className="btnaligwitselect">
              <div className="filterbtn">
                <FontAwesomeIcon icon={faFilter} size="lg" className="icon" />
                <p style={{ marginRight: "5px" }}>Filter</p>
              </div>
              {!isEdit ? (
                <div>
                  <div className="addbtn"  onClick={() => openOnChange(null, "add")}>
                    <FontAwesomeIcon icon={faPlus} size="lg" className="icon"   />
                    <p style={{ marginRight: "5px" }}>Add</p>

                  </div>
                </div>
              ) :
                null}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={userDataList}

            pagination={{
              
              defaultCurrent: 1, 
              total: objectLength, 
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50'],
              showTotal: (total, range) =>(
                <div style={{left:0,fontSize: "14px", color: "#595959",position:'absolute' }}>
                  Showing {range[1]} of {total}
                </div>
              ) 
              }}
           className="tablecontent"
          />
        </div>
      ) : (
        <Studentopr
          record={selectedDate}
          onClose={onClose}
          type={teb}
          selectedId={selectedId}
        />
      )}
    </div>
  );
}

export default Studentdetails;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams,useSearchParams } from "react-router-dom";
import { Layout, Menu, Typography, Tabs } from "antd";
import Institute from '../Component/Institute/Institute'
import Subject from '../Component/Subject/Subject'
import Class from '../Component/Class/Class'
import Vehicle from '../Component/vehicle/vehicle'
import Department from '../Component/Department/Department'
import Billing from '../Component/Billing/Billing'
import Departmentinfo from "../Component/Departmentadmin/Departmentinfo";
import Classtimetable from "../Component/Departmentadmin/Classtimetable";
import Coursemanaegement from "../Component/Departmentadmin/Coursemanagement";
import Teacherupload from "../Component/Departmentadmin/Teacherupload";


const { TabPane } = Tabs;
function DepartmentAdmincon() {
    const navigate = useNavigate();
    const { source } = useParams();
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState("institute"); 

    // const [tab, setTab] = useState(source);
    useEffect(() => {
   
        const source = searchParams.get("tab");
        if (source) {
          setTab(source);
        }
      }, [searchParams]);

    
console.log(source,"3a4wse5drftigyui")
    const changeTab = (value) => {
        navigate(`/department-admin?${value}`);
        setTab(value)
    }


    return (
        <div className="Tabcontainer">
            <Tabs defaultActiveKey={tab}
                tabBarGutter={48}
                centered
                onChange={changeTab}
                // activeKey={source || ""} 
                className="tabviewsubcon">
                <TabPane tab={<p >Department info</p>} key="Department info" className="tabbtn"></TabPane>
                <TabPane tab="classtimetable" key="classtime table"></TabPane>
                <TabPane tab="Course Management" key="Course Management"></TabPane>
            </Tabs>


            {tab == "Department info" ? <Departmentinfo /> :
                tab == "classtime table" ? <Teacherupload /> :
                    tab == "Course Management" ? <Coursemanaegement /> :''
                         }

        </div>)


}

export default DepartmentAdmincon
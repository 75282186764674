

// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

// const PerformanceChart = () => {
//   const data = {
//     labels: ['Tam', 'Eng', 'Mat', 'Phy', 'Che', 'Cs', 'Bio', 'Zol'],
//     datasets: [
//       {
//         label: 'Fail',
//         data: [20, 15, 20, 15, 12, 10, 25, 30],
//         backgroundColor: '#EC7C74',
//         borderRadius: 20,  // Fixed typo here  
//         barThickness: 10,
//       },
//       {
//         label: 'Pass',
//         data: [80,85, 80, 85, 88, 90, 75, 70 ],
//         backgroundColor: '#00ADC4',
//         borderRadius: 20,  // Fixed typo here
//         barThickness: 10,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,  // Allows the chart to adjust its aspect ratio
//     plugins: {
//       legend: {
//         display: true,  // Set to true if you want the legend visible
//       },
//     },
//     scales: {
//       x: {
//         stacked: true,
//       },
//       y: {
//         stacked: true,
//         beginAtZero: true,
//         max: 100,
//       },
//     },
//   };

//   return <Bar data={data} options={options} />;
// };

// export default PerformanceChart;


import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip } from 'chart.js';
import { Select } from "antd";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip);

const { Option } = Select;


const PerformanceChart = () => {
  const data = {
    labels: ['Tam', 'Eng', 'Mat', 'Phy', 'Che', 'Cs', 'Bio', 'Zol'],
    datasets: [
      {
        label: 'Fail',
        data: [20, 15, 20, 15, 12, 10, 25, 30],
        backgroundColor: '#EC7C74',
        borderRadius: 20,
        barThickness: 10,
      },
      {
        label: 'Pass',
        data: [80, 85, 80, 85, 88, 90, 75, 70],
        backgroundColor: '#00ADC4',
        borderRadius: 20,
        barThickness: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
          lineWidth: 0.5, // Thin grid lines
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 100,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
          lineWidth: 0.5, // Thin grid lines
        },
        ticks: {
          stepSize: 20, // Optional: Adjust tick spacing for clarity
        },
      },
    },
  };

  return (
    <div style={{ position: 'relative', height: '300px' }}>
      <div style={{display:'flex'}}>
      <h4>Student's Performance</h4>
      <Select
          placeholder="Choose gender"
          style={{ width: 100, borderRadius: '50px' }}
          defaultValue="all"
        >
          <Option value="all">Choose gender</Option>
          <Option value="Female">Female</Option>
          <Option value="male">male</Option>
          <Option value="other">other</Option>
        </Select>
      </div>
      
      {/* Chart */}
      <div style={{height:'240px'}}>
      <Bar data={data} options={options} />

      </div>

      {/* Custom Legend */}
      <div style={{ display: 'flex', justifyContent:'space-between',  gap: '20px' ,marginBottom:'10px'}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: '#00ADC4',
            }}
          ></span>
          <span style={{ fontSize: '14px', fontFamily: 'Poppins', color: '#333' }}>Pass</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: '#EC7C74',
            }}
          ></span>
          <span style={{ fontSize: '14px', fontFamily: 'Poppins, sans-serif', color: '#333' }}>Fail</span>
        </div>
      </div>
    </div>
  );
};

export default PerformanceChart;

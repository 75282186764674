// import React, { useState } from 'react'
// import { Layout, Menu, Avatar, Card, Col, Row, Statistic, Typography, Calendar, Button, Drawer, Select,option } from 'antd';
// import dayjs from 'dayjs';
// import { LeftOutlined, RightOutlined, MenuOutlined } from '@ant-design/icons';
// import DonutChart from '../../layout/base/charts/donutchart.jsx';
// import PerformanceChart from '../../layout/base/charts/barcharts.jsx';
// import FeesRevenueChart from '../../layout/base/charts/feescharts.jsx';
// import TeacherAttendanceChart from '../../layout/base/charts/TeacherAttenance.jsx';
// import DepartmentTable from '../../layout/base/charts/Departmenthead.jsx';
// import AttendanceChart from '../../layout/base/charts/studentattenancechart.jsx';
// import UserManagement from '../../Pages/userManagement.jsx';

// const { Header, Sider, Content } = Layout;



// function Dasboardcontent() {


// const [calendarDate, setCalendarDate] = useState(dayjs());
// const [showAcademicEmployee, setShowAcademicEmployee] = useState(false); // New state for toggling content



//     const cardData = {
//         totalDrivers: "5k",
//         totalTeachers: "20k",
//         totalStudents: "50k"
//     };

//     const handlePrevMonth = () => setCalendarDate(calendarDate.subtract(1, 'month'));
//     const handleNextMonth = () => setCalendarDate(calendarDate.add(1, 'month'));
//     return (
//         <>
//         <div>
//         <Content style={{ marginTop:'0px',padding: 24, background: '#f0f2f5' }}>
//             <div>
//               {/* Render the main dashboard content here */}
//               <Row gutter={[16, 16]}>
//                 <Col xs={24} sm={12} md={8} lg={5}>
//                   <Card style={{ background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)', color: 'white', height: '12rem', width: '10rem' }}>
//                     <Statistic title="Total Student's" value={cardData.totalStudents} style={{ alignItems: 'center', marginTop: '20px',color:'white' }} />
//                   </Card>
//                 </Col>
//                 <Col xs={24} sm={12} md={8} lg={5}>
//                   <Card style={{ background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)', color: 'white', height: '12rem', width: '10rem' }}>
//                     <Statistic title="Total Teacher's" value={cardData.totalTeachers} style={{ marginTop: '20px' }} />
//                   </Card>
//                 </Col>
//                 <Col xs={24} sm={12} md={8} lg={5}>
//                   <Card style={{ background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)', color: 'white', height: '12rem', width: '10rem' }}>
//                     <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{ marginTop: '20px' }} />
//                   </Card>
//                 </Col>
//                 {/* Custom Calendar */}
//                 <Col xs={24} sm={12} md={12} lg={6}>
//                   <Card style={{ textAlign: 'center', width: '20rem', height: '250px', overflow: 'hidden' }}>
//                     <Calendar
//                       fullscreen={false}
//                       value={calendarDate}
//                       onChange={(date) => setCalendarDate(date)}
//                       headerRender={() => (
//                         <div style={{ display: 'flex', justifyContent: 'space-between', }}>

//                           <Button icon={<LeftOutlined />} onClick={handlePrevMonth} style={{ backgroundColor: '#ffff', borderRadius:'20px',color: '#00ADC4',boxshadow: '18px 18px 30px 0px #D1D9E6, -18px -18px 30px 0px #FFFFFF'}} />
//                           <Typography.Title level={5} style={{margin: 0, flexGrow: 1, textAlign: 'center'}}>
//                             {calendarDate.format('MMMM YYYY')}
//                           </Typography.Title>
//                           <Button icon={<RightOutlined />} onClick={handleNextMonth} style={{ backgroundColor: '#ffff',borderRadius:'20px',color: '#00ADC4',boxshadow: '18px 18px 30px 0px #D1D9E6, -18px -18px 30px 0px #FFFFFF' }} />
//                         </div>
//                       )}
//                     />
//                   </Card>
//                 </Col>
//               </Row>

//               {/* Charts and Tables */}
//               <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
//                 <Col xs={24} md={12}>
//                   <Card >

//                     <DonutChart />
//                   </Card>
//                 </Col>
//                 <Col xs={24} md={12}>
//                   <Card title="Student's Performance" style={{ height: '18rem' }}>
//                     <PerformanceChart style={{ width: '40rem', height: '20rem' }} />
//                   </Card>
//                 </Col>
//                 <Col xs={24} md={12}>
//                <Card title="Fees & Revenue " style={{ height: '20rem' }} >
//                  <FeesRevenueChart style={{width:'40rem',height:'25rem'}}/>
//                </Card>
//              </Col>
//                 <Col xs={24} md={12}>
//                   <Card title="Teacher's Attendance" style={{ height: '20rem' }} >
//                     <TeacherAttendanceChart />
//                   </Card>
//                 </Col>
//                 <Col xs={24} md={12}>
//                   <Card  title="Student's Attendance" >
//                     <AttendanceChart />
//                   </Card>
//                 </Col>
//                 <Col xs={24} md={12}>
//                <Card>
//                  <DepartmentTable />
//                </Card>
//              </Col>
//               </Row>
//             </div>
//         </Content>
//         </div>
//         </>
//     );
// };
// export default Dasboardcontent


import React, { useState } from 'react';
import { Layout, Card, Col, Row, Statistic, Typography, Calendar, Button } from 'antd';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import DonutChart from '../../layout/base/charts/donutchart.jsx';
import PerformanceChart from '../../layout/base/charts/barcharts.jsx';
import FeesRevenueChart from '../../layout/base/charts/feescharts.jsx';
import TeacherAttendanceChart from '../../layout/base/charts/TeacherAttenance.jsx';
import DepartmentTable from '../../layout/base/charts/Departmenthead.jsx';
import AttendanceChart from '../../layout/base/charts/studentattenancechart.jsx';
import Eventmanage from '../../layout/base/charts/Eventmanage.jsx'
import './Dasboard.css'

const { Content } = Layout;

function Dasboardcontent() {
  const [calendarDate, setCalendarDate] = useState(dayjs());

  const cardData = {
    totalDrivers: '25k',
    totalTeachers: '20k',
    totalStudents: '50k',
  };

  const handlePrevMonth = () => setCalendarDate(calendarDate.subtract(1, 'month'));
  const handleNextMonth = () => setCalendarDate(calendarDate.add(1, 'month'));

  return (
    <Content style={{ margin: '0px', padding: 24, background: '#f0f2f5' }}>
      <Row gutter={[16, 16]}>
        {/* Cards */}
        <Col xs={24} sm={12} md={24} lg={6} >
          <Card
            style={{
              background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)',
              height: '260px',
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 

            }}
          >
            <div >
              <div className='carddetailscon' style={{ fontSize: '2rem', fontWeight: 'normal', marginBottom: '8px', color: '#fff', fontFamily: 'poppins' }} >
                {cardData.totalStudents}
              </div>
              <div className='carddetailscon' style={{ fontSize: '1rem', fontWeight: 'normal', color: '#fff', fontFamily: 'poppins' }}>
                Total Student's
              </div>
            </div>
            {/* <Statistic title="Total Student's" value={cardData.totalStudents} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} /> */}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={24} lg={6}>
          <Card
            style={{
              background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)',
              color: 'white',
              height: '260px',
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
            }}
          >
            <div >
              <div style={{ fontSize: '2rem', fontWeight: 'normal', marginBottom: '8px', color: '#fff', fontFamily: 'poppins' }}>
                {cardData.totalTeachers}
              </div>
              <div style={{ fontSize: '1rem', fontWeight: 'normal', color: '#fff', fontFamily: 'poppins' }}>
                Total Teacher's
              </div>
            </div>
            {/* <Statistic title="Total Teacher's" value={cardData.totalTeachers} style={{ marginTop: '20px' }} /> */}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={24} lg={6}>
          <Card
            style={{
              background: 'linear-gradient(160deg, #00ADC4 0%, #00535E 100%)',
              color: 'white',
              height: '260px',
                            display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
            }}
          >
            <div style={{alignItems:'center'}}>
              <div style={{ fontSize: '2rem', fontWeight: 'normal', marginBottom: '8px', fontFamily: 'poppins' }}>
                {cardData.totalDrivers}
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'normal', fontFamily: 'poppins' }}>
                Total Driver's
              </div>
            </div>
            {/* <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{marginTop:'20px'}} /> */}
          </Card>
        </Col>
        {/* Calendar */}
        <Col xs={24} sm={24} md={24} lg={6}>
          <Card style={{ textAlign: 'center', height: '260px',  overflow: 'hidden', fontfamily: 'poppins' }}>
            <Calendar
              fullscreen={false}
              value={calendarDate}
              className="custom-calendar"
                      onChange={(date) => setCalendarDate(date)}
              headerRender={() => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                  <Button
                    icon={<LeftOutlined />}
                    onClick={handlePrevMonth}
                    className="calendar-nav-btn"
                    style={{
                      backgroundcolor: 'rgb(255, 255, 255)',
                      borderRadius: '20px',
                      color: '#0c8292',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px',
                      border: 'none',
                    }}
                  />
                  <Typography.Title level={4} style={{ margin: 0, textAlign: 'center' }}>
                    {calendarDate.format('MMMM YYYY')}
                  </Typography.Title>
                  <Button
                    icon={<RightOutlined />}
                    onClick={handleNextMonth}
                    className="calendar-nav-btn"

                    style={{
                      backgroundcolor: 'rgb(255, 255, 255)',
                      borderRadius: '20px',
                      color: '#0c8292',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px',
                      border: 'none'
                    }}
                  />
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>
      {/* Charts and Tables */}
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Card style={{height:'400px'}}>
            <DonutChart />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Card  style={{height:'400px'}}>
            <div >
            <PerformanceChart  />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Card  style={{height:'400px'}}>
            <Eventmanage  />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card style={{height:'400px'}}>
            <FeesRevenueChart style={{height:'400px'}} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card className='chartatt'>
            <TeacherAttendanceChart  />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card  style={{height:'400px'}}>
            <AttendanceChart style={{height:'300px'}}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} style={{height:'400px'}}>
            <DepartmentTable style={{height:'400px'}}/>
        </Col>
      </Row>
    </Content>
  );
}

export default Dasboardcontent;

import React from 'react';
import { Form, Input, Select, Button, Upload, Row, Col, message } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddStudentForm = ({ selectedId, onClose, record, type }) => {

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Form Values:', values);
    message.success(`${type === 'edit' ? 'Employee updated' : 'Employee added'} successfully!`);
    form.resetFields();
    onClose();
  };

  return (

    <div className="Accodamicwholeopr" >
      <h2>
        {!selectedId
          ? 'Add Employee'
          : type === 'edit'
            ? 'Edit Employee'
            : 'View Employee'}
      </h2>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={record || {}}
      >
        <h3
          className='Accadamich3'
        >
          Basic Details
        </h3>
        {(type === 'view' || type === 'edit') && (
          <div style={{ width: 200, height: 200 }}>
            <img src="https://i.pravatar.cc/40?img=12" alt="ii" style={{ width: 200, height: 200 }} />
          </div>
        )}
        <div className='whitebagcontainer'>
          <Row gutter={[16, 16]}>
            {/* First Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: 'Please enter first name!' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            {/* Last Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: 'Please enter last name!' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please enter email!' }]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* First Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="dateofbirth"
                label="Date of Birth"
                rules={[{ required: true, message: 'Please enter Date of Birth!' }]}
              >
                <Input placeholder="Date of Birth" />
              </Form.Item>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="Gender"
                label="Gender"
                rules={[{ required: true, message: 'Please enter Gender!' }]}
              >
                <Input placeholder="Gender" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="mobilenumber"
                label="Mobile Number"
                rules={[{ required: true, message: 'Please enter Mobile Number!' }]}
              >
                <Input placeholder="Mobile Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* First Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: 'Please enter Address!' }]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="nationality"
                label="Nationality"
                rules={[{ required: true, message: 'Please enter Nationality!' }]}
              >
                <Input placeholder="Nationality" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="employeerole"
                label="Employee Role"
                rules={[{ required: true, message: 'Please enter Employee Role!' }]}
              >
                <Input placeholder="Employee Role" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <h3
          className='Accadamich3'
        >
          Employement Details
        </h3>
        <div className='whitebagcontainer'>
          <Row gutter={[16, 16]}>
            {/* First Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="joiningdate"
                label="Joining Date"
                rules={[{ required: true, message: 'Please enter Joining Date!' }]}
              >
                <Input placeholder="Joining Date" />
              </Form.Item>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="employeeId"
                label="Employee ID"
                rules={[{ required: true, message: 'Please enter Employee ID!' }]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="assignedclass"
                label="Assigned Class"
                rules={[{ required: true, message: 'Please enter Assigned Class!' }]}
              >
                <Input placeholder="Assigned Class" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* First Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="subjects"
                label="Subjects"
                rules={[{ required: true, message: 'Please enter Subjects!' }]}
              >
                <Input placeholder="Subjects" />
              </Form.Item>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="employmenttype"
                label="Employment Type"
                rules={[{ required: true, message: 'Please enter Employment Type!' }]}
              >
                <Input placeholder="Employment Type" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="department"
                label="Department"
                rules={[{ required: true, message: 'Please enter Department!' }]}
              >
                <Input placeholder="Department" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <h3
          className='Accadamich3'
        >
          Educational and Professional Details
        </h3>
        <div className='whitebagcontainer'>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="qualification"
                label="Qualification"
                rules={[{ required: true, message: 'Please enter Qualification!' }]}
              >
                <Input placeholder="Qualification" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="Specialization"
                label="specialization"
                rules={[{ required: true, message: 'Please enter Specialization!' }]}
              >
                <Input placeholder="Specialization" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="experience"
                label="Experience"
                rules={[{ required: true, message: 'Please enter Experience!' }]}
              >
                <Input placeholder="Experience" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {type === 'edit' || type === 'add' ?
          <>
            <h3
              className='Accadamich3'>
              Photo Upload
            </h3>
            <div>
              <div className='whitebagcontainer'>
                <div className="upload">
                  <Upload maxCount={1} beforeUpload={() => false}>
                    <Button
                      icon={<UploadOutlined />}
                      className='uploadbackground'

                    >
                      Upload
                    </Button>
                  </Upload>
                  <p className="file-size-info" >
                    <InfoCircleOutlined /> Max file size should be 500kb
                  </p>
                </div>
              </div>
            </div>
          </> : null


        }


        <div className="form-buttons" >
          <Form.Item>
            <Button
              type="primary"
              className="modifybutton"
              htmlType="submit"
              style={{ marginRight: '10px' }}
            >
              {type === 'edit' ? 'Update' : 'Add'}
            </Button>
            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>

  );
};

export default AddStudentForm;


import React from "react";
import { Form, Input, Upload, Select, Button, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import noticepng from '../../Assets/png/noticepng.png'

const { TextArea } = Input;
const { Option } = Select;

const  Circularopr = ({onClose}) => {
  const handleFinish = (values) => {
    console.log("Form Values: ", values);
  };

  return (
    <div
     className="Accodamicwholeopr"
      // style={{
        // maxWidth: "900px",
        // margin: "50px auto",
        // background: "#f6f8fb",
        // padding: "20px 30px",
        // borderRadius: "10px",
        // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      //   marginTop: "20px"
      // }}
    >
      <h2 style={{ marginBottom: "20px" }}>circular</h2>
      <Form
        layout="vertical"
        onFinish={handleFinish}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ flex: 1 }}>
          <Form.Item
            label="Subject"
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          <Form.Item
            label="Attachment"
            name="attachment"
            valuePropName="fileList"
          >
            <div className="upload">
              <Upload maxCount={1} beforeUpload={() => false} >
                <Button icon={<UploadOutlined />} className="modifybutton" >
                  Upload Document
                </Button>
              </Upload>
              
          </div>
          </Form.Item>

          <Form.Item
            label="Audience"
            name="audience"
            rules={[{ required: true, message: "Please select an audience!" }]}
          >
            <Select placeholder="Select Audience">
              <Option value="students">Students</Option>
              <Option value="teachers">Teachers</Option>
              <Option value="all">All</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Select Department"
            name="department"
            rules={[
              { required: true, message: "Please select a department!" },
            ]}
          >
            <Select placeholder="Select Department">
              <Option value="science">Science</Option>
              <Option value="math">Math</Option>
              <Option value="arts">Arts</Option>
            </Select>
          </Form.Item>

         
        </div>
            <div style={{border:'solid 1px white',marginLeft:'20px'}}>
              
            </div>
        <div
          style={{
            marginLeft: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={noticepng}
            alt="illustration"
            style={{ marginBottom: "20px",width:200,height:200 }}
          />
          <Button type="primary" style={{ marginBottom: "10px", width: "100px", background: "#ffcc00", borderColor: "#ffcc00" }}>
            Save
          </Button>
          <Button type="primary" style={{ marginBottom: "10px", width: "100px", background: "#00cccc", borderColor: "#00cccc" }}>
            Publish
          </Button>
          <Button style={{ marginBottom: "10px", width: "100px", background: "#ff6666", borderColor: "#ff6666", color: "white" }} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default  Circularopr;

import React, { useState } from "react";
import { Form, Input, Button, Steps, message, Row, Col, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";


import { Radio, Typography } from "antd";

import './Register.css'

const { Text } = Typography;

const { Step } = Steps;

const RegistrationForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [fileName, setFileName] = React.useState('');
    const [value, setValue] = useState("Yes");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [dynamicFields, setDynamicFields] = useState([]);
    const [schoolName, setSchoolName] = useState('')
    const [hasBranches, setHasBranches] = useState(null);

    const [formData, setFormData] = useState({
        schoolName: '',
        primaryEmail: '',
        contactNumber: '',
        schoolAddress: '',
        city: '',
        state: '',
        country: '',
        adminName: '',
        adminEmail: '',
        adminPhoneNumber: '',
        fileName: '',
        schoolType: '',
        password: '',
        confirmPassword: ''
    });


    const addField = () => {
        const newField = {
            id: Date.now(), // Unique ID for the dynamic field
            branchName: "",
            branchAddress: "",
            branchContactNumber: "",
            branchAdminName: "",
            branchAdminEmail: "",
            branchAdminMobileNumber: "",
        };
        setDynamicFields([...dynamicFields, newField]);
    };
    const removeField = (id) => {
        setDynamicFields(dynamicFields.filter((field) => field.id !== id));
    };


    const [fields, setFields] = useState([{ id: 1 }]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleFileChange = (info) => {
        if (info.fileList.length > 0) {
            setFileName(info.fileList[0].name);
        } else {
            setFileName('');
        }
    };

    const onNext = async () => {
        try {
            // Validate form fields
            await form.validateFields();
            // Move to next step if no validation errors
            setCurrentStep(currentStep + 1);
        } catch (error) {
            message.error("Please fill all required fields!");
        }
    };

    const onFinish = (values) => {
        console.log("Form Submitted:", values);
        message.success("Registration successful!");
    };

    

    const handleRadioChange = (e) => {
        setHasBranches(e.target.value); // Update state on change
    };


    return (
        <div style={{ height: "100vh", width: "100vw", margin: "0" }}>
            <Row style={{ height: "100%" }}>
                {/* Left Side */}
                <Col
                    xs={24} // Full width on extra-small screens
                    sm={12} // Half width on small screens and above
                    style={{
                        backgroundColor: "#f0f2f5", // Light gray for left side
                        height: "100%",
                        padding: "20px",
                    }}
                >
                    <div style={{ height: "100%" }}>
                        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                            Register Your Account
                        </h2>

                        <Steps current={currentStep} labelPlacement="vertical"  >

                            <Step title="School Detail" />
                            <Step title="Admin Detail" />
                            <Step title="Branch Detail" />
                        </Steps>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            style={{ marginTop: "20px" }}
                        >
                            {currentStep === 0 && (
                                <div>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} lg={24}>
                                            <Form.Item
                                                label="School Name"
                                                name="schoolName"
                                                rules={[{ required: true, message: "School Name is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter School Name" style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Primary Email"
                                                name="primaryEmail"
                                                rules={[
                                                    { required: true, message: "Email is required!" },
                                                    { type: "email", message: "Enter a valid email!" },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter Primary Email" style={{ width: '100%' }}                             
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Contact Number"
                                                name="contactNumber"
                                                rules={[
                                                    { required: true, message: "Contact Number is required!" },
                                                    {
                                                        pattern: /^\d{10}$/,
                                                        message: "Enter a valid 10-digit contact number!",
                                                    },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter Contact Number" style={{ width: '100%' }}                                                  />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} lg={24}>
                                            <Form.Item
                                                label="School Address"
                                                name="schoolAddress"
                                                rules={[{ required: true, message: "Address is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter School Address" style={{ width: '100%' }}                             
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]} >
                                        <Col span={7}>
                                            <Form.Item
                                                label="City"
                                                name="city"
                                                rules={[{ required: true, message: "City is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter City" style={{ width: '100%' }}                            
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                label="State"
                                                name="state"
                                                rules={[{ required: true, message: "State is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter State" style={{ width: '100%' }}                             
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                label="Country"
                                                name="country"
                                                rules={[{ required: true, message: "Country is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter Country" style={{ width: '100%' }}                            
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {/* Add content for steps 2 and 3 as needed */}
                            {currentStep === 1 && (
                                <div>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} lg={24}>
                                            <Form.Item
                                                label="Admin Name"
                                                name="adminname"
                                                rules={[{ required: true, message: "Admin Name is required!" }]}
                                            >
                                                <Input size="large" placeholder="Enter Admin Name" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Admin Email"
                                                name="adminemail"
                                                rules={[
                                                    { required: true, message: "Admin Email is required!" },
                                                    { type: "email", message: "Enter a valid email!" },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter Admin Email" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Admin Phone Number"
                                                name="adminphonenumber"
                                                rules={[
                                                    { required: true, message: "Admin Phone Number is required!" },
                                                    {
                                                        pattern: /^\d{10}$/,
                                                        message: "Enter a valid 10-digit contact number!",
                                                    },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter Contact Number" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label="Cover Image" name="Choose File">
                                                <div style={{ backgroundColor: 'white', width: '250px', display: 'flex' }}>
                                                    <Input style={{ width: '150px', border: 'none' }} value={fileName} readOnly placeholder="No file chosen" />
                                                    <Upload style={{ borderRadius: "8px" }}
                                                        // beforeUpload={handleFileChange}
                                                        showUploadList={false}>
                                                        <Button
                                                            icon={<UploadOutlined />}
                                                            className="insaddbtnacc"
                                                        >
                                                            Choose file
                                                        </Button>
                                                    </Upload>
                                                </div>

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="School Type"
                                                name="schooltype"
                                                rules={[
                                                    { required: true, message: "School Type is required!" },

                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter School Type " style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    { required: true, message: "Password is required!" },
                                                    {
                                                        pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                                                        message:
                                                            "Enter valid password",
                                                    },]}

                                            >
                                                <Input.Password size="large" placeholder="Enter Password " style={{ width: '100%' }}  iconRender={(visible) =>
                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="confirm Password"
                                                name="confirmpassword"
                                                rules={[
                                                    { required: true, message: "confirm Password is required!" },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue("password") === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error("Passwords do not match!")
                                                            );
                                                        },
                                                    }),

                                                ]}
                                            >
                                                <Input.Password size="large" placeholder="Enter confirm Password " style={{ width: '100%' }} 
                                                    iconRender={(visible) =>
                                                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>


                            )}


                            {currentStep === 2 &&
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Text strong style={{ fontSize: "16px" }}>
                                            Does the School have Branches?
                                        </Text>
                                        <Radio.Group  onChange={handleRadioChange}>
                                            <Radio value="Yes" >Yes</Radio>
                                            <Radio value="No" >No</Radio>
                                        </Radio.Group>
                                    </div>


                                    {/* dynamic field */}
                                    <Form layout="vertical">
                                        {/* Static Fields */}
                                        <Row gutter={16} style={{ marginBottom: "16px" }}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Name"
                                                    name="static_branchName"
                                                    rules={[{ required: true, message: "Branch Name is required!" }]}
                                                >
                                                    <Input size="large" placeholder="Enter Branch Name" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Address"
                                                    name="static_branchAddress"
                                                    rules={[{ required: true, message: "Branch Address is required!" }]}
                                                >
                                                    <Input size="large" placeholder="Enter Branch Address" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Contact Number"
                                                    name='branchContactNumber'
                                                    rules={[
                                                        { required: true, message: "Branch Contact Number is required!" },
                                                        { pattern: /^\d{10}$/, message: "Enter a valid 10-digit contact number!" },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder="Enter Contact Number" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Admin Name"
                                                    name="branchAdminName"
                                                    rules={[{ required: true, message: "Branch Admin Name is required!" }]}
                                                >
                                                    <Input size="large" placeholder="Enter Admin Name" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Admin Email"
                                                    name="branchAdminEmail"
                                                    rules={[
                                                        { required: true, message: "Branch Admin Email is required!" },
                                                        { type: "email", message: "Enter a valid email!" },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder="Enter Admin Email" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Branch Admin Mobile Number"
                                                    name="branchAdminMobileNumber"
                                                    rules={[
                                                        { required: true, message: "Admin Mobile Number is required!" },
                                                        { pattern: /^\d{10}$/, message: "Enter a valid 10-digit contact number!" },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder="Enter Mobile Number" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* Dynamic Fields */}
                                        {dynamicFields.map((field) => (
                                            <Row gutter={16} key={field.id} style={{ marginBottom: "16px" }}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Name"
                                                        name={`branchName_${field.id}`}
                                                        rules={[{ required: true, message: "Branch Name is required!" }]}
                                                    >
                                                        <Input size="large" placeholder="Enter Branch Name" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Address"
                                                        name={`branchAddress_${field.id}`}
                                                        rules={[{ required: true, message: "Branch Address is required!" }]}
                                                    >
                                                        <Input size="large" placeholder="Enter Branch Address" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Contact Number"
                                                        name={`branchContactNumber_${field.id}`}
                                                        rules={[
                                                            { required: true, message: "Branch Contact Number is required!" },
                                                            { pattern: /^\d{10}$/, message: "Enter a valid 10-digit contact number!" },
                                                        ]}
                                                    >
                                                        <Input size="large" placeholder="Enter Contact Number" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Admin Name"
                                                        name={`branchAdminName_${field.id}`}
                                                        rules={[{ required: true, message: "Branch Admin Name is required!" }]}
                                                    >
                                                        <Input size="large" placeholder="Enter Admin Name" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Admin Email"
                                                        name={`branchAdminEmail_${field.id}`}
                                                        rules={[
                                                            { required: true, message: "Branch Admin Email is required!" },
                                                            { type: "email", message: "Enter a valid email!" },
                                                        ]}
                                                    >
                                                        <Input size="large" placeholder="Enter Admin Email" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Branch Admin Mobile Number"
                                                        name={`branchAdminMobileNumber_${field.id}`}
                                                        rules={[
                                                            { required: true, message: "Admin Mobile Number is required!" },
                                                            { pattern: /^\d{10}$/, message: "Enter a valid 10-digit contact number!" },
                                                        ]}
                                                    >
                                                        <Input size="large" placeholder="Enter Mobile Number" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} style={{ textAlign: "right" }}>
                                                    <MinusCircleOutlined
                                                        style={{ fontSize: "20px", color: "red" }}
                                                        onClick={() => removeField(field.id)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}

                                        {/* Add Button */}
                                        <Row>
                                            <div className="addiconreg" style={{ marginLeft: '500px' }}>
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{ fontSize: "20px", color: "#00ADC4", cursor: "pointer" }}
                                                    onClick={addField}
                                                />
                                            </div>

                                        </Row>
                                    </Form>

                                </div>}

                            <div style={{ marginTop: "10px", textAlign: "center" }}>
                                {currentStep > 0 && (
                                    <Button
                                        className="regprebtng"
                                        style={{}}
                                        onClick={() => setCurrentStep(currentStep - 1)}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {currentStep < 2 && (
                                    <Button type="primary"
                                        onClick={onNext} className="regprebtng" >
                                        Next
                                    </Button>
                                )}
                                {currentStep === 2 && (
                                    <Button type="primary" htmlType="submit" className="regprebtng">
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </div>
                </Col>

                {/* Right Side */}
                <Col
                    xs={24} // Full width on extra-small screens
                    sm={12} // Half width on small screens and above
                    style={{
                        backgroundColor: " #f0f2f5", // White for right side
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    <div style={{ height: "100%", background: ' #00535E', borderRadius: '10px', boxshadow: '18px 18px 30px 0px #D1D9E640' }}>

                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationForm;

// import React from 'react';
// import { Progress, Row, Col, Typography, Space } from 'antd';

// const { Title, Text } = Typography;

// const AttendanceChart = () => {
//   return (
//     <div style={{ backgroundColor: '#f5f7fa', padding: '24px', borderRadius: '8px' }}>
//       <Title level={4}>Student's Attendance</Title>

//       <Row justify="center" style={{ margin: '20px 0' }}>
//         {/* Outer Circle - Overall */}
//         <Progress
//           type="circle"
//           percent={7}
//           width={200}
//           strokeColor="#FF6B6B"
//           trailColor="#D3D3D3"
//           format={() => (
//             <div style={{ fontSize: '24px', color: '#FF6B6B' }}>
//               <Text>9</Text>
//             </div>
//           )}
//         />

//         {/* Middle Circle - Girls */}
//         <Progress
//           type="circle"
//           percent={96}
//           width={150}
//           strokeColor="#FFDD67"
//           trailColor="#D3D3D3"
//           style={{
//             position: 'absolute',
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: -1,
//           }}
//           format={() => (
//             <div style={{ fontSize: '24px', color: '#FFDD67' }}>
//               <Text>16</Text>
//             </div>
//           )}
//         />

//         {/* Inner Circle - Boys */}
//         <Progress
//           type="circle"
//           percent={95}
//           width={100}
//           strokeColor="#6BCBFF"
//           trailColor="#D3D3D3"
//           style={{
//             position: 'absolute',
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: -2,
//           }}
//           format={() => (
//             <div style={{ fontSize: '24px', color: '#6BCBFF' }}>
//               <Text>25</Text>
//             </div>
//           )}
//         />
//       </Row>

//       {/* Legend */}
//       <Row justify="center" style={{ marginTop: '20px' }}>
//         <Space size="large">
//           <Col>
//             <Text strong style={{ color: '#6BCBFF' }}>25</Text>
//             <br />
//             <Text>Boys (95%)</Text>
//           </Col>
//           <Col>
//             <Text strong style={{ color: '#FFDD67' }}>16</Text>
//             <br />
//             <Text>Girls (96%)</Text>
//           </Col>
//           <Col>
//             <Text strong style={{ color: '#FF6B6B' }}>9</Text>
//             <br />
//             <Text>Overall (7%)</Text>
//           </Col>
//         </Space>
//       </Row>
//     </div>
//   );
// };

// export default AttendanceChart;


import React from "react";
import { Doughnut } from "react-chartjs-2";
import boygirl from '../../../Assets/Svg/boygirl.svg';
import { Select } from 'antd';
import  './studentatteenance.css'

const { Option } = Select;

const AttendanceChart = () => {
  const boysData = {
    datasets: [
      {
        data: [95, 5],
        backgroundColor: ["#FAE27C", "#EC7C74"],
        borderWidth: 0,
      },
    ],
  };

  const girlsData = {
    datasets: [
      {
        data: [96, 4],
        backgroundColor: ["#ffff", "#ffff"],
        borderWidth: 0,
      },
    ],
  };

  const overallData = {
    datasets: [
      {
        data: [7, 93],
        backgroundColor: ["#EC7C74", "#C3EBFA"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "75%", // Adjusts the inner radius size
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <div style={{display:'flex'}}>
      <h3 style={{marginTop:'10px'}}>Student's Attendance</h3>
      <div style={{marginLeft:'10px'}}>
      <Select placeholder="Choose Class" style={{ width: 100,marginTop:'10px',boxshadow:'0 10px 30px rgba(0, 0, 0, 0.2)'}}>
          <Option value="mathematics" style={{boxshadow: '0 10px 20px rgba(255, 255, 255, 0.15)' }}>Mathematics</Option>
          <Option value="science">Science</Option>
          <Option value="english">English</Option>
        </Select>
        <Select placeholder="Choose Department" style={{ width: 100 ,marginLeft:'6px',marginTop:'10px' }}>
          <Option value="mathematics">Mathematics</Option>
          <Option value="science">Science</Option>
          <Option value="english">English</Option>
        </Select>
      </div>
      
      </div>
      
      <div style={{ position: "relative", display: "inline-block" ,
 }}>
        <div style={{width:'15rem',height:'15rem'}}>
        <div style={{ position: "absolute", top: 0, left: 0 ,width:'10rem',}}>
          <Doughnut data={overallData} options={options} width={300} height={300} />
        </div>
        <div style={{ position: "absolute", top: 25, left: 25,width:'5rem', }}>
          <Doughnut data={girlsData} options={options} width={250} height={250} />
        </div>
        <div style={{ position: "absolute", top: 25, left: 25 ,width:'7rem',}}>
          <Doughnut data={boysData} options={options} width={240} height={240} />
        </div>
        </div>
        

        {/* Central Icon */}
        <div style={{
          position: "absolute",
          top: "25%",
          left: "20%",
          // transform: "translate(-50%, -50%)",
          // fontSize: "30px",
          width:'60px'
          
        }}>
           <img src={boygirl} alt="hi" />
        </div>
      </div>

      {/* Labels for each group */}
      
      <div className="chart-labels" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div className="label present" >
          <span
            className="dot"
            style={{
              backgroundColor: '#FAE27C',
              marginLeft: '2rem', // Corrected to 'marginLeft' in camelCase
              width: '10px',
              height: '10px'
            }}
          ></span>
          <span>girls</span>
          <span className="percentage"></span>
        </div>
        <div className="label absent">
          <span
            className="dot"
            style={{
              backgroundColor: '#C3EBFA',
              // border: 'solid 2px red',
              // width: '200px',
              // marginLeft:'200px'
            }}
          ></span>
          <span>Boys</span>
          <span className="percentage"></span>
        </div>
        <div className="label absent">
          <span
            className="dot"
            style={{
              backgroundColor: '#EC7C74',
            }}
          ></span>
          <span>overall</span>
          <span className="percentage"></span>
        </div>
      </div>
    </div>
  );
};

export default AttendanceChart;
// import React from "react";
// import { Doughnut } from "react-chartjs-2";
// import boygirl from '../../../Assets/Svg/boygirl.svg'
// import { Select } from "antd";
// import './donutchart.css'

// const GenderChart = () => {
//   const boysData = {
//     datasets: [
//       {
//         data: [95, 5],
//         backgroundColor: ["#FAE27C", "#F1F1F1"],
//         borderWidth: 0,
//       },
//     ],
//   };

//   const girlsData = {
//     datasets: [
//       {
//         data: [96, 4],
//         backgroundColor: ["#ffff", "#ffff"],
//         borderWidth: 0,
//       },
//     ],
//   };

//   const overallData = {
//     datasets: [
//       {
//         data: [7, 93],
//         backgroundColor: ["#F1F1F1", "#C3EBFA"],
//         borderWidth: 0,
//       },
//     ],
//   };

//   const options = {
//     cutout: "75%", // Adjusts the inner radius size
//     plugins: {
//       tooltip: { enabled: false },
//     },
//   };

//   return (
//     <div style={{ position: "relative", textAlign: "center" }}>
//       <div style={{display:'flex'}}>
//         <h2 style={{fontSize: '14px', fontFamily: 'Poppins, sans-serif', color: '#333'}}>Student's</h2>
//         {/* <select className='Selectoptioncon'>
//           <option>
//             Girls
//           </option>
//           <option>
//             Boys
//           </option>
//         </select> */}
//          <Select
//           placeholder="Choose gender"
//           style={{ width: 100,borderRadius:'50px' }}
//           // onChange={handleDepartmentChange}
//           defaultValue="all"
//         >
//           <Option value="all">Choose Year</Option>
//           <Option value="mathematics">2000</Option>
//           <Option value="science">2010</Option>
//           <Option value="english">2020</Option>
//         </Select>
//       </div>

//       </div>

//       <div style={{position: "relative", display: "inline-block",}}>

//         {/* Three concentric Doughnut charts */}
//         <div style={{ width: '10rem', height: '10rem' }}>
//           <div style={{ position: "absolute", top: 0, left: 0, width: '10rem', }}>
//             <Doughnut data={overallData} options={options} width={300} height={300} />
//           </div>
//           <div style={{ position: "absolute", top: 25, left: 25, width: '5rem', }}>
//             <Doughnut data={girlsData} options={options} width={250} height={250} />
//           </div>
//           <div style={{ position: "absolute", top: 25, left: 25, width: '7rem', }}>
//             <Doughnut data={boysData} options={options} width={240} height={240} />
//           </div>
//         </div>


//         {/* Central Icon */}
//         <div style={{
//           position: "absolute",
//           top: "35%",
//           left: "30%",
//           // transform: "translate(-50%, -50%)",
//           // fontSize: "30px",
//           width: '60px'

//         }}>
//           <img src={boygirl} alt="hi" />
//         </div>
//       </div>

     
//       <div className="chart-labels" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
//         <div className="label present" >
//           <span
//             className="dot"
//             style={{
//               backgroundColor: '#FAE27C',
//               marginLeft: '2rem', // Corrected to 'marginLeft' in camelCase
//               // width: '10px',
//               // height: '10px'
//             }}
//           ></span>
//           <span>girls</span>
//           <span className="percentage"></span>
//         </div>
//         <div className="label absent">
//           <span
//             className="dot"
//             style={{
//               backgroundColor: '#C3EBFA',
             
//             }}
//           ></span>
//           <span>Boys</span>
//           <span className="percentage"></span>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default GenderChart;
import React from "react";
import { Doughnut } from "react-chartjs-2";
import boygirl from '../../../Assets/Svg/boygirl.svg';
import { Select } from "antd";
import './donutchart.css';

const { Option } = Select;

const GenderChart = () => {
  const boysData = {
    datasets: [
      {
        data: [95, 5],
        backgroundColor: ["#FAE27C", "#F1F1F1"],
        borderWidth: 0,
      },
    ],
  };

  const girlsData = {
    datasets: [
      {
        data: [96, 4],
        backgroundColor: ["#ffff", "#ffff"],
        borderWidth: 0,
      },
    ],
  };

  const overallData = {
    datasets: [
      {
        data: [7, 93],
        backgroundColor: ["#F1F1F1", "#C3EBFA"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "75%", // Adjusts the inner radius size
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      {/* Header Section */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2 style={{ fontSize: '14px', fontFamily: 'Poppins, sans-serif', color: '#333' }}>Student's</h2>
        <Select
          placeholder="Choose gender"
          style={{ width: 100, borderRadius: '50px' }}
          defaultValue="all"
        >
          <Option value="all">Choose gender</Option>
          <Option value="Female">Female</Option>
          <Option value="male">male</Option>
          <Option value="other">other</Option>
        </Select>
      </div>

      {/* Doughnut Charts */}
      <div style={{ position: "relative", display: "inline-block" ,marginTop:'2rem'}}>
        <div style={{ width: '10rem', height: '10rem' }}>
          <div style={{ position: "absolute", top: 0, left: 0, width: '10rem' }}>
            <Doughnut data={overallData} options={options} width={300} height={300} />
          </div>
          <div style={{ position: "absolute", top: 25, left: 25, width: '5rem' }}>
            <Doughnut data={girlsData} options={options} width={250} height={250} />
          </div>
          <div style={{ position: "absolute", top: 25, left: 25, width: '7rem' }}>
            <Doughnut data={boysData} options={options} width={240} height={240} />
          </div>
        </div>

        {/* Central Icon */}
        <div
          style={{
            position: "absolute",
            top: "35%",
            left: "30%",
            width: '60px',
          }}
        >
          <img src={boygirl} alt="boy and girl icon" />
        </div>
      </div>

      {/* Chart Labels */}
      <div className="chart-labels" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div className="label present">
          <span
            className="dot"
            style={{
              backgroundColor: '#FAE27C',
              marginLeft: '2rem',
            }}
          ></span>
          <span>Girls</span>
          <span className="percentage"></span>
        </div>
        <div className="label absent">
          <span
            className="dot"
            style={{
              backgroundColor: '#C3EBFA',
            }}
          ></span>
          <span>Boys</span>
          <span className="percentage"></span>
        </div>
      </div>
    </div>
  );
};

export default GenderChart;

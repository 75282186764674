import React from "react";
import './style/variable.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './layout/base/Home.jsx';
import AcademicEmployee from './Pages/Acadamicemployee.jsx';
import Login from './Pages/Login.jsx';
import Register from './Pages/Register.jsx';
import Usermanagementcon from '../src/Pages/Usermanagementcon.jsx';
import Dashboard from "./Pages/Dashboard.jsx";
import Drivers from './Pages/Drivers.jsx';
import Student from './Pages/Student.jsx';
import Notices from './Pages/Notices.jsx';
import Sidebar from './layout/base/Sidebar.jsx';
import Circular from './Pages/Circular.jsx';
import Setting from './Pages/setting.jsx';
import Parent from './Pages/Parent.jsx';
import DepartmentAdmincon from './Pages/DepartmentAdmincon.jsx';
import Fees from './Pages/Fees.jsx';
import Report from "./Pages/Report.jsx";

function App() {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    return (
        <Router>
            <Routes>
                {/* Routes for authenticated users */}
                {token ? (
                    <Route path="/" element={<Sidebar />}>
                        <Route index element={<Dashboard />} /> {/* Default route */}
                        <Route path="academic-employee" element={<AcademicEmployee />} />
                        <Route path="user-management" element={<Usermanagementcon />} />
                        <Route path="drivers" element={<Drivers />} />
                        <Route path="students" element={<Student />} />
                        <Route path="parent" element={<Parent />} />
                        <Route path="notices" element={<Notices />} />
                        <Route path="fees" element={<Fees />} />
                        <Route path="reports" element={<Report />} />
                        <Route path="circular" element={<Circular />} />
                        <Route path="setting" element={<Setting />} />
                        <Route path="department-admin" element={<DepartmentAdmincon />} />
                    </Route>
                ) : (
                    // Routes for unauthenticated users
                    <>
                        <Route path="/" element={<Login />} />
                        <Route path="register" element={<Register />} />
                    </>
                )}
            </Routes>
        </Router>
    );
}

export default App;




import React, { useEffect,useState } from 'react';
import dayjs from 'dayjs';
import Sidebar from '../layout/base/Sidebar.jsx';
import {getUserData}from "../helper/index.js"
import Dasboardcontent from '../Component/Dasboardcontent/Dasboardcontent.jsx';

const Dashboard = () => {
  let userData =getUserData()
  const [calendarDate, setCalendarDate] = useState(dayjs());
  

  useEffect(() => {
    let local = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)
    if (!local) {
        window.location.href = "/login"
    }
}, [])
console.log(userData.role,"sdfghjk")

  return (
  <>
   <Dasboardcontent/>
  </>
  );
};

export default Dashboard;





